import { createApp } from 'vue'
import App from './App.vue'
import router  from './router'
import store from "@/store";
import "./assets/style.css";

import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'

import {createI18n} from "vue-i18n";
import {ru} from "@/locales/ru";
import {en} from "@/locales/en";

const language = localStorage.getItem('language')
const i18n = createI18n({
    defaultLocale: language ? language : 'ru',
    locale: language ? language : 'ru',
    fallbackLocale: language ? language : 'ru',
    allowComposition: true,
    messages: {
        en,
        ru,
    },
    legacy: false,
})

router.afterEach(() => {
    document.querySelector('body').classList.remove('overflow-hidden');
    document.querySelector('body').classList.remove('bg-gray');
    document.querySelector('body').classList.remove('bg-white');
})
const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueTippy);
app.mount('#app');
