<template>
    <ul v-if="auth && user.id">
        <li class="py-3 px-4 rounded-t-xl border-b-[1px] border-greyBorder group hover:bg-blue">
            <a href="https://lc.oasisexperience.cc/user/profile" class="flex items-center cursor-pointer">
                <svg class="stroke-black group-hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 21V19C19 17.9391 18.5786 16.9217 17.8284 16.1716C17.0783 15.4214 16.0609 15 15 15H9C7.93913 15 6.92172 15.4214 6.17157 16.1716C5.42143 16.9217 5 17.9391 5 19V21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-sm font-medium ml-4 group-hover:text-white">{{t('myProfile')}}</span>
            </a>
        </li>

        <li class="py-3 px-4 border-b-[1px] border-greyBorder group hover:bg-blue">
            <a href="https://lc.oasisexperience.cc/user/travelers" class="flex items-center cursor-pointer">
                <svg class="stroke-black group-hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H6C4.93913 15 3.92172 15.4214 3.17157 16.1716C2.42143 16.9217 2 17.9391 2 19V21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22 20.9999V18.9999C21.9993 18.1136 21.7044 17.2527 21.1614 16.5522C20.6184 15.8517 19.8581 15.3515 19 15.1299" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 3.12988C16.8604 3.35018 17.623 3.85058 18.1676 4.55219C18.7122 5.2538 19.0078 6.11671 19.0078 7.00488C19.0078 7.89305 18.7122 8.75596 18.1676 9.45757C17.623 10.1592 16.8604 10.6596 16 10.8799" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-sm font-medium ml-4 group-hover:text-white">{{t('travelers')}}</span>
            </a>
        </li>

        <li class="py-3 px-4 border-b-[1px] border-greyBorder group hover:bg-blue">
            <a href="https://lc.oasisexperience.cc/user/visa" class="flex items-center cursor-pointer">
                <svg class="stroke-black group-hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 18C11.1046 18 12 17.1046 12 16C12 14.8954 11.1046 14 10 14C8.89543 14 8 14.8954 8 16C8 17.1046 8.89543 18 10 18Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 10L11.5 14.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 11L16 12" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-sm font-medium ml-4 group-hover:text-white">{{t('myVisas')}}</span>
            </a>
        </li>

        <li class="py-3 px-4 border-b-[1px] border-greyBorder group hover:bg-blue">
            <a href="#" class="flex items-center cursor-pointer">
                <svg class="stroke-black group-hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.8 19.2L16 11L19.5 7.5C21 6 21.5 4 21 3C20 2.5 18 3 16.5 4.5L13 8L4.8 6.2C4.3 6.1 3.9 6.3 3.7 6.7L3.4 7.2C3.2 7.7 3.3 8.2 3.7 8.5L9 12L7 15H4L3 16L6 18L8 21L9 20V17L12 15L15.5 20.3C15.8 20.7 16.3 20.8 16.8 20.6L17.3 20.4C17.7 20.1 17.9 19.7 17.8 19.2Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-sm font-medium ml-4 group-hover:text-white">{{t('myTours')}}</span>
            </a>
        </li>

        <li class="py-3 px-4 rounded-b-xl group hover:bg-blue">
            <a href="#" class="flex items-center cursor-pointer" @click="logout">
                <svg class="stroke-black group-hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M16 17L21 12L16 7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M21 12H9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-sm font-medium ml-4 group-hover:text-white">{{t('logout')}}</span>
            </a>
        </li>
    </ul>


    <ul v-else>
        <li class="py-3 px-4 rounded-t-xl border-b-[1px] border-greyBorder group hover:bg-blue">
            <a href="/login" class="flex items-center cursor-pointer">
                <svg class="stroke-black group-hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 21V19C19 17.9391 18.5786 16.9217 17.8284 16.1716C17.0783 15.4214 16.0609 15 15 15H9C7.93913 15 6.92172 15.4214 6.17157 16.1716C5.42143 16.9217 5 17.9391 5 19V21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-sm font-medium ml-4 group-hover:text-white">{{t('authorization')}}</span>
            </a>
        </li>
        <li class="py-3 px-4 rounded-b-xl group hover:bg-blue">
            <a href="/register" class="flex items-center cursor-pointer">
                <svg class="stroke-black group-hover:stroke-white" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10 17L15 12L10 7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 12H3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span class="text-sm font-medium ml-4 group-hover:text-white">{{t('registration')}}</span>
            </a>
        </li>
    </ul>
</template>

<script setup>
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {useStore} from "vuex";

defineProps(['auth'])
const store = useStore()
const {t} = useI18n()

const user = computed(() => store.state.user)

const logout = async () => {
  localStorage.removeItem("exp");
  localStorage.removeItem("token");
  store.commit('setUser', '')

  window.location.href = 'https://lc.oasisexperience.cc/logout'

  // const response = await AxiosIns.postData("/api/logout", null, {headers: {
  //     'Authorization': 'Bearer ' + localStorage.getItem('token')
  //   }});
}
</script>