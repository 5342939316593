import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/HomeView.vue";
import About from "./views/AboutView.vue";
import VisaB211A from "./views/services/Visa-B211A.vue";
import BusinessConsulting from "./views/BusinessConsultingView.vue";
import B211A from "./views/news/B211A.vue";
import TourPage from "./views/news/TourPage.vue";
import Questions from "./views/QuestionsView.vue";
import Services from "./views/ServicesView.vue";
import News from "./views/NewsView.vue";
import Tours from "./views/ToursView.vue";
import VehicleRental from "./views/VehicleRentalView.vue";
import Contacts from "./views/ContactsView.vue";
import Pages from "./views/pages/PagesView.vue";
import ForgoPassword from "@/views/ForgoPassword.vue";
import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";
import NewPassword from "@/views/NewPassword.vue";
import ReviewsView from "@/views/ReviewsView.vue";
import SocialLogin from "@/views/SocialLogin.vue";

const checkAuth = () => {
    const token = localStorage.getItem('token');
    const exp = localStorage.getItem('exp');

    if (!token || !exp) {
        return false;
    }

    // Если токен истек
    if (Date.now() >= exp * 1000) {
        return false;
    }

    return true;
}

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: Home, meta: { title: 'Главная' } },
        { path: '/about', component: About, meta: { title: 'О нас' } },
        { path: '/services/:id', component: VisaB211A, meta: { title: 'VisaB211A' } },
        { path: '/business-consulting', component: BusinessConsulting, meta: { title: 'Бизнес на Бали' } },
        { path: '/journals/:id', component: B211A, meta: { title: 'B211A' } },
        { path: '/pages/:slug', component: Pages, meta: { title: 'Pages' } },
        { path: '/questions', component: Questions, meta: { title: 'Вопросы и ответы' } },
        { path: '/reviews', component: ReviewsView, meta: { title: 'Отзывы о нас' } },
        { path: '/services', component: Services, meta: { title: 'Наши сервисы и услуги' } },
        { path: '/journals', component: News, meta: { title: 'Преcсцентр' } },
        { path: '/tours', component: Tours, meta: { title: 'Туры' } },
        { path: '/tours/:id', component: TourPage, meta: { title: 'TourPage' } },
        { path: '/vehicle-rental', component: VehicleRental, meta: { title: 'Аренда Транспорта' } },
        { path: '/contacts', component: Contacts, meta: { title: 'Контакты' } },
        { path: '/login', component: LoginView, name: 'login', meta: { title: 'Sign In', guest: true } },
        { path: '/social/login', component: SocialLogin, name: 'social-login', meta: { title: 'Sign In', guest: true } },
        { path: '/register', component: RegisterView, name: 'register', meta: { title: 'Create an Account', guest: true } },
        { path: '/forgot-password', component: ForgoPassword, name: 'forgot-password', meta: { title: 'Forgot Password ', guest: true } },
        { path: '/new-password', component: NewPassword, name: 'new-password', meta: { title: 'New Password ', guest: true } },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, left: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    if (to.meta.guest && checkAuth()) {
        next('/');
    } else {
        next();
    }
});

export default router;
