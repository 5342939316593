<template>
    <section class="home-about_wrapper">
        <div class="container">
            <div class="flex flex-row justify-between items-start flex-wrap">
                <h2 class="home-about_title">{{data.title}}</h2>
                <div v-if="props.type !== 'about_page'" class="flex-col-between-start w-[630px]">
                    <div v-html="data.description" class="text-lg about-description"></div>
                    <custom-button
                        :is-link="true"
                        linkTo="/about"
                        :text="t('more')"
                        width="245px"
                        class="home-about_link h-[70px] text-center"
                    />
                </div>
                <div class="flex-col-between-start w-[630px]" v-if="props.type === 'about_page'">
                    <div v-html="data.description" class="text-lg about-description"></div>
                    <custom-button
                        width="245px"
                        :text="t('writeToUs')"
                        classes="home-about_link magnetic"
                        :isButton="true"
                        @click="openModal"
                    />
                </div>
            </div>
            <swiper
                :modules="modules"
                :space-between="10"
                :speed="600"
                :breakpoints="swiperOptions.breakpoints"
                :pagination="{ clickable: true }"
                ref="mySwiper"
                class="pb-[56px] mt-[78px] block md:hidden"
            >
                <swiper-slide
                    v-for="(item ,i) in gallery"
                    :key="i"
                    :class="`slide-${i+1}`"
                >
                    <img :src="item.original_url" class="w-full h-full rounded-[24px]" alt="">
                </swiper-slide>
            </swiper>
            <div class="flex-col md:flex-row items-center mt-[78px] justify-center flex-wrap hidden md:flex">
                <div class="flex flex-col items-center justify-start md:w-[calc(50%-24px)]">
                    <div v-if="gallery[0]" class="bg-cube-color lg:h-[400px] rounded-[24px] w-full mb-[16px] md:mb-[48px]">
                        <img
                            :src="gallery[0].original_url"
                            class="w-full h-full rounded-[24px]"
                            alt=""
                            @mouseover="mouseOverHandler('about-img_magnetic', 'increase')"
                            @mouseout="mouseOutHandler"
                        >
                    </div>
                    <div v-if="gallery[1]" class="bg-cube-color lg:h-[400px] rounded-[24px] w-full">
                        <img
                            :src="gallery[1].original_url"
                            class="w-full h-full rounded-[24px]"
                            alt=""
                            @mouseover="mouseOverHandler('about-img_magnetic', 'increase')"
                            @mouseout="mouseOutHandler"
                        >
                    </div>
                </div>
                <div class="flex flex-col items-center justify-start md:w-[calc(50%-24px)] md:ml-[48px]">
                    <div v-if="gallery[2]" class="bg-cube-color lg:h-[400px] rounded-[24px] w-full">
                        <img
                            :src="gallery[2].original_url"
                            class="w-full h-full rounded-[24px]"
                            alt=""
                            @mouseover="mouseOverHandler('about-img_magnetic', 'increase')"
                            @mouseout="mouseOutHandler"
                        >
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterPopup
        :showModal="showModal"
        @close="closeModal"
    />
</template>

<script setup>
// Import Swiper Vue.js components
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {computed, defineProps, ref} from "vue";
import CustomButton from "@/components/CustomButton.vue";


const { t } = useI18n()


const props = defineProps({
    type: {
        type: String,
        default: ''
    },
    data: {
        type: Object,
        default: () => ({})
    }
})

let showModal = ref(false)
const openModal = () => {
    showModal.value = true;
    document.querySelector('body').classList.remove('overflow-auto');
    document.querySelector('body').classList.add('overflow-hidden');
}
const closeModal = () => {
    showModal.value = false;
    document.querySelector('body').classList.remove('overflow-hidden');
    document.querySelector('body').classList.add('overflow-auto');
}

const gallery = computed(() => {
   return props.data.gallery ? Object.values(props.data.gallery) : []
})

const swiperOptions = {
    breakpoints: {
        768: {
            pagination: true,
            slidesPerView: 1,
            speed: 0
        },
    }
}
const modules = [Pagination]

import { useStore } from 'vuex'
import FooterPopup from "@/components/FooterPopup.vue";
import {useI18n} from "vue-i18n";

const store = useStore()

const mouseOverHandler = (name, text) => {
    store.commit('setCursorInner', t(text))
    store.commit('setCursorClass', name)
};
const mouseOutHandler = () => {
    store.commit('setCursorInner', "")
    store.commit('setCursorClass', "")

};
</script>
<style>
.about-description p {
    margin-bottom: 40px;
}
</style>
  