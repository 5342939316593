<template>
    <h3 class="text-title-color text-5xl font-extrabold mb-[24px] section_title mt-[120px]" id="2">
      {{ t('faq') }}
    </h3>
    <app-accordion
            v-for="(item,i) in props.data"
            :key="i"
            class="mt-[18px]"
            :opened="i === 0 ? 'block' : ''"
    >
        <template v-slot:title>
            <h4 class="consulting-question">{{item.question}}</h4>
        </template>
        <template v-slot:content>
            <p class="text-[14px] lg:text-[18px] mt-[20px] md:mt-0">
                {{item.answer}}
            </p>
        </template>
    </app-accordion>
</template>

<script setup>
import AppAccordion from "@/components/Ui/QuestionsAccordionBlock.vue";
import {useI18n} from "vue-i18n";
const props = defineProps(['data'])
const { t } = useI18n()
</script>

<style scoped>

</style>