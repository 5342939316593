<template>
    <div class="not-auth_wrapper h-full min-h-screen flex-center bg-center bg-fixed bg-cover bg-no-repeat relative">
      <a href="/" class="absolute top-6 left-6 md:top-10 md:left-10 cursor-pointer flex-center z-10">
        <div class="flex-center rounded-full bg-gray-strong w-8 h-8 md:w-10 md:h-10 ">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.35041 14.9129C8.71653 14.5468 8.71653 13.9532 8.35041 13.5871L3.70083 8.9375L14.5625 8.9375C15.0803 8.9375 15.5 8.51777 15.5 8C15.5 7.48223 15.0803 7.0625 14.5625 7.0625L3.70083 7.0625L8.35041 2.41291C8.71653 2.0468 8.71653 1.4532 8.35041 1.08709C7.9843 0.72097 7.39071 0.72097 7.02459 1.08709L0.774588 7.33709C0.408472 7.7032 0.408472 8.29679 0.774588 8.66291L7.02459 14.9129C7.3907 15.279 7.9843 15.279 8.35041 14.9129Z" fill="#3772FF"/>
          </svg>
        </div>
        <span class="hidden md:block text-white text-lg font-semibold ml-4">{{t('backToHome')}}</span>
      </a>
      <div class="flex flex-col lg:flex-row justify-center md:justify-between items-center w-full container">
        <div class="hidden lg:block">
          <img src="/images/header-title-white-2.svg" class="logo-oaut mb-8" alt="logo">
        </div>
        <div class="bg-white flex-center rounded-0 md:rounded-[40px] p-5 md:p-12 w-full md:w-[600px] h-full min-h-screen md:min-h-fit md:h-auto relative">
          <slot></slot>
        </div>
      </div>
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const {t} = useI18n()
</script>

<style scoped>
.not-auth_wrapper {
    background-image: url("@/assets/images/header-bg1.jpg");
}
</style>