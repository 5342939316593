<template>
    <div class="flex flex-col lg:flex-row justify-between items-center flex-wrap">
        <div
            v-for="(item,i) in props.data"
            :key="i"
            class="group"
            :class="i === 0 ? 'services-company_types-first' : i === props.data.length - 1 ? 'services-company-types-large' : 'services-company_types bg-gray lg:w-[calc(100%/2-16px)]'"
        >
            <div class="consulting-invest_number focus:ring-blue-500 focus:border-blue-500">
                <p>{{i < 9 ? 0 : ''}}{{i+1}}</p>
            </div>
            <div class="flex flex-col justify-between w-full items-start z-10">
                <h5
                    class="text-title-color font-extrabold text-[28px] sm:text-[32px]"
                    :class="{
                        'text-white': i === 0
                    }"
                >
                    {{item.title}}
                </h5>
                <p
                    class="text-lg text-title-color mt-[16px]"
                    :class="{
                        'text-white': i === 0
                    }"
                >
                    {{item.body}}
                </p>
            </div>
            <router-link
                :to="`pages/${item.slug}`"
                class="consulting-invest_link"
                :class="{
                    'bg-white mt-[32px] flex flex-row justify-between items-center text-activeServiceButtonColor text-xl px-[24px] py-[11px] sm:py-[20.5px] rounded-[40px] sm:w-[64px] sm:h-[64px] duration-800 transition-all group-hover:w-fit': i === 0
                }"
            >
                <p class="consulting-invest_link-text duration-600">
                   {{ t('more') }}
                </p>
                <svg width="18" height="18" viewBox="0 0 18 18" class=" ml-[11px] sm:ml-0 group-hover:ml-[11px]"
                     fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                          fill="#21242C" class="fill-activeServiceButtonColor"/>
                </svg>
            </router-link>
        </div>
        <consulting-form />
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const props = defineProps(['data'])
import ConsultingForm from "@/components/ConsultingPage/ConsultingForm.vue";
const { t } = useI18n()
</script>