<template>
    <HeaderOtherPages :title="t('services')"></HeaderOtherPages>
    <div class="services-wrap">
        <div class="container py-[56px] sm:py-[120px]">
            <div class="flex flex-row items-center justify-start flex-wrap md:ml-[16px]">
                <div
                        v-for="(item, i) in categories"
                        :key="i"
                        class="services-type"
                        :class="{ active: showServices === item.id }"
                        @click="setCurrentActive(item.id)"
                >
                    <p>{{ item.name }}</p>
                </div>
            </div>
            <div class="flex flex-row items-center mt-[20px] justify-start flex-wrap">
                <div
                        v-for="(service, i) in services"
                        :key="i"
                        class="w-full xl:w-[calc(50%-32px)] mb-[32px] md:mx-[16px]"
                >
                    <div class="services-block group duration-500">
                        <div class="flex flex-col justify-between w-full items-start z-10">
                            <h4 class="text-title-color font-extrabold text-[28px] sm:text-[32px]">
                                {{ service.title }}</h4>
                            <p class="text-title-color text-base sm:text-lg mt-4 mb-[10px]">
                                {{ service.short_description }}</p>
                            <div class="flex flex-row justify-start items-center flex-wrap">
                                <div v-for="(item, idx) in service.extensions"
                                     :key="idx"
                                >
                                    <tippy theme="light">
                                       <div class="services-info info">
                                           <p class="mr-[8px] info-hover:text-reviewsOrange">
                                               {{ item.text }}
                                           </p>
                                           <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                               <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                     fill="#FF6939"/>
                                           </svg>
                                       </div>

                                        <template #content>
                                            <div class="custom-tooltip">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                        fill="#FF6939"/>
                                                </svg>
                                                <span>{{item.info}}</span>
                                            </div>
                                        </template>
                                    </tippy>
                                </div>
                            </div>
                        </div>
                        <router-link
                            :to="`/services/${service.id}`"
                            class="services-link duration-800"
                        >
                            <p class="services-link_text duration-600 ">
                               {{ t('more') }}</p>
                            <svg width="18" height="18" class="ml-[11px] sm:ml-0 group-hover:ml-[11px]"
                                 viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                                      fill="white" class="fill-activeServiceButtonColor"/>
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import HeaderOtherPages from '@/components/Ui/HeaderOtherPages.vue'

import {onMounted, ref} from "vue";
import AxiosIns from "@/services/apiService";
import {Tippy} from "vue-tippy";
import {useI18n} from "vue-i18n";


const { t } = useI18n()

let services = ref([])
let showServices = ref()

const setCurrentActive = async (id) => {
    showServices.value = id
    const response = await AxiosIns.getData(`/api/services?category=${id}`);
    services.value = response.data
}

let categories = ref([])

const getCategories = async () => {
    const response = await AxiosIns.getData("/api/services/categories");
    categories.value = response.data
}
const getServices = async () => {
    const response = await AxiosIns.getData("/api/services");
    services.value = response.data
}

onMounted(async () => {
    await getCategories()
    await getServices()
})

onMounted(() => {
    document.querySelector('body').classList.add('bg-white');
})
</script>
      