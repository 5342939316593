<template>
    <section class="home-questions_wrap">
        <div class="container">
            <h2 class="home-questions_title"> {{ t('faq') }}</h2>


            <app-accordion class="mt-[18px]" v-for="(item, i) in faq" :key="i">
                <template v-slot:title>
                    <h4 class="home-questions-text">
                        {{item.question}}
                    </h4>
                </template>
                <template v-slot:content>
                    <p class="text-base lg:text-lg mt-[20px] md:mt-0">
                        {{item.answer}}
                    </p>
                </template>
            </app-accordion>
        </div>
    </section>
</template>

<script setup>
import AppAccordion from "@/components/Ui/QuestionsAccordionBlock.vue"

// отправляем запрос получаем ответ
import AxiosIns from "@/services/apiService"
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

const { t } = useI18n()
onMounted(() => {
  document.querySelector("body").classList.add("bg-white");
});

const faq = ref([]);

(async () => {
  const response = await AxiosIns.getData("/api/questions?show=1");
  faq.value = response.data;
})();
</script>

<style scoped>

</style>
  