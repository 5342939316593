<template>
    <router-link
            v-if="props.isLink"
            :to="props.linkTo"
            class="relative overflow-hidden animated-link"
            :class="classes"
            :style="{width, height}"
            @mouseover="mouseOverHandler('button-magnetic', '')"
            @mouseout="mouseOutHandler"
    >
        <span class="flex-center absolute">
            {{text}}
            <svg width="18" height="18" class="ml-[11px]" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                  :fill="svgColor"/>
            </svg>
        </span>
        <span class="flex-center absolute">
          {{text}}
          <svg width="18" height="18" class="ml-[11px]" viewBox="0 0 18 18" fill="none"
               xmlns="http://www.w3.org/2000/svg">
          <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                :fill="svgColor"/>
          </svg>
        </span>
    </router-link>
    <button
            v-else-if="isButton"
            class="relative overflow-hidden animated-link"
            :class="classes"
            :type="type"
            :style="{width, height}"
            @click="$emit('click')"
            @mouseover="mouseOverHandler('button-magnetic', '')"
            @mouseout="mouseOutHandler"
    >
         <span class="flex-center absolute">
          {{text}}
            <svg width="18" height="18" class="ml-[11px]" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                    :fill="svgColor"/>
            </svg>
         </span>
         <span class="flex-center absolute">
          {{text}}
            <svg width="18" height="18" class="ml-[11px]" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                    :fill="svgColor"/>
            </svg>
         </span>

    </button>
</template>

<script setup>

import {useStore} from "vuex";

const props = defineProps({
    isLink: {
        type: Boolean,
        default: false
    },
    isButton: {
        type: Boolean,
        default: false
    },
    linkTo: {
        type: String,
        default: '/'
    },
    text: {
        type: String,
        default: ''
    },
    classes: {
        type: String,
        default: ''
    },
    width: {
        type: String,
        default: ''
    },
    height: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: ''
    },
    svgColor: {
        type: String,
        default: 'white'
    }
})

const store = useStore()

const mouseOverHandler = (name, text) => {
    store.commit('setCursorInner', text)
    store.commit('setCursorClass', name)
};
const mouseOutHandler = () => {
    store.commit('setCursorInner', "")
    store.commit('setCursorClass', "")

};

</script>

<style scoped>

</style>