<template>
    <div class="container">
        <div class="flex flex-col md:flex-row justify-between items-start md:items-center w-full mb-[18px]">
            <h3 class="text-white text-5xl font-extrabold"> {{ t('business.services') }}</h3>
            <a href="#"
               class="consulting-business-href">
                {{ t('allServices') }}
                <svg width="18" height="18" class="ml-[11px]" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                          fill="white"/>
                </svg>
            </a>
        </div>
        <div class="flex flex-col lg:flex-row justify-between items-center flex-wrap">
            <div
                v-for="(item,i) in props.data"
                :key="i"
                class="consulting-business-block group"
            >
                <div class="flex flex-col justify-between w-full items-start z-10">
                    <h5 class="home-services_white-title">{{item.title}}</h5>
                    <p class="home-services_white-text">{{item.short_description}}</p>
                    <div class="flex flex-row justify-start items-center flex-wrap">
                        <div
                            v-for="(info,idx) in item.extensions"
                            :key="idx"
                            class="consulting-business-info info">
                            <p class="mr-[8px] info-hover:text-reviewsOrange">{{info}}</p>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                      fill="#FF6939"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <router-link :to="`services/${item.id}`"
                             class="consulting-business-link duration-800">
                    <p class="consulting-invest_link-text duration-600">
                       {{ t('more') }}</p>
                    <svg width="18" height="18" class="ml-[11px] sm:ml-0 group-hover:ml-[11px]" viewBox="0 0 18 18"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                              fill="white" class="fill-activeServiceButtonColor"/>
                    </svg>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const props = defineProps(['data'])
const { t } = useI18n()
</script>

<style scoped>

</style>