<template>
    <HeaderOtherPages :title="t('bussinesConsaltings')"></HeaderOtherPages>
    <div class="consulting-wrap">
        <div class="container">
            <div class="flex flex-col lg:flex-row justify-between items-start">
                <h2 class="consulting-title">
                    {{businessData.title}}
                </h2>
                <div class="flex flex-col justify-between items-start lg:w-[630px] lg:ml-[32px]">
                    <div class="text-lg mb-[40px] current-business-description" v-html="businessData.description"></div>
                    <custom-button
                        width="245px"
                        :text="t('writeToUs')"
                        classes="consulting-black_btn duration-600 magnetic"
                        :isButton="true"
                        @click="openModal"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="consulting-blue_wrap">
        <div class="container">
            <h2 class="consulting-white_title">{{ t('whyChooseUs') }}</h2>
            <div class="flex flex-row justify-between flex-wrap items-center">
                <div v-for="(item, i) in consultingChooseData" :key="i" class="consulting-choose">
                    <div class="consulting-choose_img">
                        <span v-html="item.icon"></span>
                    </div>
                    <h4 class="consulting-choose_title">{{item.title}}</h4>
                    <p class="text-base text-title-color mt-4">{{item.text}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="py-[100px] bg-white rounded-[32px] sm:rounded-[80px]">
        <div class="container flex flex-row justify-between items-start">
            <div class="w-[30%] static xl:sticky top-[120px] left-0 hidden lg:block">
                <h5
                    v-for="item in sidebar"
                    :key="item.key"
                    class="consulting-sidebar duration-500"
                    :class="{sidebar_active: item.key === currentSection}"
                >
                    <img src="../../public/images/sidebar-border.svg"
                         class="hidden mr-[12px]" alt="">
                    {{item.text}}
                </h5>
            </div>
            <div class="lg:w-[70%] lg:pl-8">
                <h3 class="text-title-color text-5xl font-extrabold mb-[24px] section_title" id="1">
                    {{businessData.create_company_title}}
                </h3>
                <p class="text-2xl py-[24px] font-semibold">
                    {{businessData.create_company_short_description}}
                </p>
                <div class="text-lg current-business-description" v-html="businessData.create_company_description"></div>
              <custom-button
                  width="245px"
                  :text="t('more')"
                  svgColor="#21242C"
                  classes="news-btn mt-8 magnetic"
                  :isLink="true"
                  linkTo="/pages/investirovanie_na_bali"
              />
                <question-answer :data="createQuestions" />

            </div>
        </div>
        <investment-directions :data="openCompanyPages" />
    </div>
    <div class="mt-32">
      <v-results-block class="bg-bg-orange"></v-results-block>
    </div>
    <div class="py-[100px] bg-white rounded-[32px] sm:rounded-[80px] inline-block w-full -mt-[100px] relative">
        <div class="container flex flex-row justify-between items-start">
            <div class="w-[30%] static xl:sticky top-[120px] left-0 hidden lg:block">
                <h5
                    v-for="item in sidebar2"
                    :key="item.key"
                    class="consulting-sidebar duration-500"
                    :class="{sidebar_active: item.key === currentSection}"
                >
                    <img
                        src="../../public/images/sidebar-border.svg"
                        class="hidden mr-[12px]" alt=""
                    >
                    {{item.text}}
                </h5>
            </div>
            <div class="lg:w-[70%] lg:pl-8">
                <h3 class="text-title-color text-5xl font-extrabold mb-[24px] section_title" id="1">
                    {{businessData.open_company_title}}
                </h3>
                <p class="text-2xl py-[24px] font-semibold">
                    {{businessData.open_company_short_description}}
                </p>
                <div class="text-lg current-business-description" v-html="businessData.open_company_description"></div>

                <custom-button
                    width="245px"
                    :text="t('more')"
                    svgColor="#21242C"
                    classes="news-btn mt-8 magnetic"
                    :isLink="true"
                    linkTo="/pages/otkrytie_kompanii_v_indonezii"
                />
                <question-answer :data="openCompanyQuestions" />
            </div>
        </div>
        <div class="container">
            <h3 class="text-title-color text-5xl font-extrabold mt-[120px] mb-[48px] section_title" id="3">
              {{ t('business.companyType') }}
            </h3>

            <company-types :data="createCompanyPages" />
        </div>
    </div>
    <div class="business-services_wrap">
        <business-services :data="services" />
    </div>
    <div class="business-articles_wrap">
        <business-articles :data="news" />
    </div>
    <FooterPopup
        :showModal="showModal"
        @close="closeModal"
    />
</template>

<script setup>
import HeaderOtherPages from '../components/Ui/HeaderOtherPages.vue';
import InvestmentDirections from "@/components/ConsultingPage/InvestmentDirections.vue";
import QuestionAnswer from "@/components/ConsultingPage/QuestionAnswer.vue";
import BusinessServices from "@/components/ConsultingPage/BusinessServices.vue";
import BusinessArticles from "@/components/ConsultingPage/BusinessArticles.vue";
import CompanyTypes from "@/components/ConsultingPage/CompanyTypes.vue";
import {onMounted, ref} from "vue";
import AxiosIns from "@/services/apiService";
import CustomButton from "@/components/CustomButton.vue";
import FooterPopup from "@/components/FooterPopup.vue";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import VResultsBlock from "@/components/SectionsPage/Home/VResultsBlock.vue";
const { t } = useI18n()
let showModal = ref(false);
let currentSection = ref(0);
const store = useStore()

const sidebar = [
    {key: 1, text: 'Общая информация'},
    {key: 2, text: 'Важно знать'},
    {key: 3, text: 'Направления'},
];

const sidebar2 = [
    {key: 1, text: 'Общая информация'},
    {key: 2, text: 'Важно знать'},
    {key: 3, text: 'Типы компаний'},
];

const closeModal = () => {
    showModal.value = false;
    store.commit('setIsNewsModal', '')
    document.querySelector('body').classList.remove('overflow-hidden');
    document.querySelector('body').classList.add('overflow-auto');
}
const openModal = () => {
    showModal.value = true;
    store.commit('setIsNewsModal', true)
    document.querySelector('body').classList.remove('overflow-auto');
    document.querySelector('body').classList.add('overflow-hidden');
}

let businessData = ref([])
let consultingChooseData = ref([])
let createCompanyPages = ref([])
let openCompanyPages = ref([])
let services = ref([])
let createQuestions = ref([])
let openCompanyQuestions = ref([])
let news = ref([])

onMounted(async () => {
    const {data} = await AxiosIns.getData("/api/business");
    businessData.value = data
    createCompanyPages.value = data.create_company_pages.pages
    openCompanyPages.value = data.open_company_pages.pages
    services.value = data.services
    news.value = data.news_category.news
    consultingChooseData.value = data.why_choose_us
    createQuestions.value = data.create_company_questions.question
    openCompanyQuestions.value = data.open_company_questions.question

    document.querySelector('body').classList.add('bg-white');
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                currentSection.value = parseInt(entry.target.getAttribute('id'))
            }
        })
    })
    document.querySelectorAll('.section_title').forEach((section) => {
        observer.observe(section)
    })
})

</script>
<style>
.current-business-description p {
    padding-bottom: 24px;
}
</style>
      