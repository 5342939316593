<template>
    <div class="flex items-center justify-between">
        <div class="flex items-center flex-wrap mr-2">
            <span class="text-grey text-sm font-semibold mr-1">{{text}}</span>
            <router-link
                :to="link"
                class="text-sm text-btnSubmit font-semibold"
            >{{linkName}}</router-link>
        </div>
      <div>
        <custom-select
            :optionsList="languages"
            :flag="{icon : require('../assets/images/united-states.svg')}"
            defaultLabel="English"
        />
      </div>
    </div>
</template>
<script setup>
import CustomSelect from "@/components/Ui/CustomSelect.vue";

defineProps({
    text: {
        type: String,
        default: ''
    },
    link: {
        type: String,
        default: ''
    },
    linkName: {
        type: String,
        default: ''
    },
})

const languages = [
    {id: 1, name: 'English', flag: require('../assets/images/united-states.svg')},
    {id: 2, name: 'Russian', flag: require('../assets/images/russia.svg')},
]
</script>