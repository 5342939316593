<template>
  <div>
      <div class="magnetic-cursor" :class="cursorWrapperClass" >
          <div
                  ref="cursorInner"
                  class="cursor-inner"
                  :class="cursorClass"
          >
              <span v-if="customCursorInner">{{customCursorInner}}</span>
              <video v-if="videoSrc" autoplay muted loop id="myVideo" class="cursor-video">
                  <source :src="videoSrc" type="video/mp4">
                  Your browser does not support HTML5 video.
              </video>
          </div>
      </div>
  </div>
</template>

<script>
// import {gsap} from 'gsap';

export default {
  mounted() {
    // window.addEventListener('DOMContentLoaded', this.initCursor);
  },
  methods: {
    // initCursor() {
    //   const cursorInner = this.$refs.cursorInner;
    //   const magneticElements = document.querySelectorAll('.magnetic');
    //
    //   const getClosestMagneticElement = (x, y) => {
    //     let closestElement = null;
    //     let closestDistance = Number.MAX_VALUE;
    //
    //     magneticElements.forEach((element) => {
    //       const rect = element.getBoundingClientRect();
    //       const centerX = rect.left + rect.width / 2;
    //       const centerY = rect.top + rect.height / 2;
    //       const distance = Math.hypot(centerX - x, centerY - y);
    //
    //       if (distance < closestDistance) {
    //         closestDistance = distance;
    //         closestElement = element;
    //       }
    //     });
    //
    //     return closestElement || document.body;
    //   };
    //
    //
    //   const onMouseMove = (e) => {
    //     const {clientX, clientY} = e;
    //     const closestElement = getClosestMagneticElement(clientX, clientY);
    //     const rect = closestElement.getBoundingClientRect();
    //     const centerX = rect.left + rect.width / 2;
    //     const centerY = rect.top + rect.height / 2;
    //     const distance = Math.hypot(centerX - clientX, centerY - clientY);
    //     const magneticDistance = 50;
    //
    //     let targetX = clientX;
    //     let targetY = clientY;
    //
    //     if (distance < magneticDistance) {
    //       targetX = centerX;
    //       targetY = centerY;
    //       cursorInner.style.backgroundColor = 'red';
    //     } else {
    //       cursorInner.style.backgroundColor = 'black';
    //     }
    //
    //     gsap.to(cursorInner, {
    //       x: targetX,
    //       y: targetY,
    //       duration: 0.3,
    //       ease: 'power2.out',
    //       overwrite: true,
    //     });
    //   };
    //
    //   const onMouseOver = () => {
    //     gsap.to(cursorInner, {
    //       scale: 1.5,
    //       duration: 0.3,
    //       ease: 'power2.out',
    //     });
    //   };
    //
    //   const onMouseOut = () => {
    //     gsap.to(cursorInner, {
    //       scale: 1,
    //       duration: 0.3,
    //       ease: 'power2.out',
    //     });
    //   };
    //
    //   window.addEventListener('mousemove', onMouseMove);
    //
    //   magneticElements.forEach((element) => {
    //     element.addEventListener('mouseover', onMouseOver);
    //     element.addEventListener('mouseout', onMouseOut);
    //   });
    // },
  },
};
</script>
<script setup>
import {useRouter} from "vue-router";
import {ref} from "vue";
const router = useRouter();

const key = ref(1)

router.beforeEach(() => {
    key.value++
})
import {gsap} from 'gsap';

import {computed} from "vue";
import { useStore } from 'vuex'

const store = useStore()
const customCursorInner = computed(() => store.state.cursorInner)

const videoSrc = computed(() => store.state.videoSrc)

const cursorClass = computed(() => store.state.cursorClass)

const cursorWrapperClass = computed(() => store.state.cursorWrapperClass)

// Function for Mouse Move Scale Change
function getScale(diffX, diffY) {
  const distance = Math.sqrt(Math.pow(diffX, 2) + Math.pow(diffY, 2));
  return Math.min(distance / 100, 0.25);
}

// Function For Mouse Movement Angle in Degrees
// function getAngle(diffX, diffY) {
//   return (Math.atan2(diffY, diffX) * 180) / Math.PI;
// }

// Save pos and velocity Objects
const pos = { x: 0, y: 0 };
const vel = { x: 0, y: 0 };
const offset = 20;

// Loop not Started Yet
let loopStarted = false;

// Caluclate Everything Function
const setFromEvent = (e) => {
  // Mouse X and Y
  const x = e.clientX;
  const y = e.clientY;

  // Animate Pos Object and calculate Vel Object Velocity
  gsap.to(pos, {
    x: x,
    y: y,
    ease: "expo.out",
    duration: 0.3,
    onUpdate: () => {
      vel.x = x - pos.x;
      vel.y = y - pos.y;
    }
  });

  // Start loop
  if (!loopStarted) {
    loop();
    loopStarted = true;
  }
};

// Start Animation loop
const loop = () => {
  const shape = document.querySelector(".magnetic-cursor");
  // const text = document.querySelector(".inside-text");
  if(!shape) return;
  // Calculate angle and scale based on velocity
  // var rotation = getAngle(vel.x, vel.y);
  var scale = getScale(vel.x, vel.y);

  // Set transform data to Jelly Blob
  gsap.to(shape, {
    x: pos.x - offset,
    y: pos.y - offset,
    // rotation: rotation + "_short",
    scaleX: 1 + scale,
    scaleY: 1 - scale
  });

  // gsap.to(text, {
  //   rotation: -rotation + "_short"
  // });

  // Animation Frame for Loop Function
  requestAnimationFrame(loop);
};

window.addEventListener("mousemove", setFromEvent);

</script>

<style scoped>
/*.magnetic-cursor {*/
/*  position: fixed;*/
/*  z-index: 9999;*/
/*}*/

.cursor-inner {
  width: 20px;
  height: 20px;
  /*background-color: black;*/
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(1);
  pointer-events: none;
}

.magnetic-cursor {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
  transform-origin: center center;
  will-change: width, height, transform, border;
  z-index: 999;
  pointer-events: none;
}
@media(max-width: 768px) {
    .magnetic-cursor {
        display: none;
    }
}
</style>
