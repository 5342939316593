<template>
  <div class="w-full">
      <h1 class="text-center text-4xl font-semibold mb-10">{{t('auth.signIn')}}</h1>
      <p class="text-center text-sm font-semibold mb-5 text-grey">{{t('auth.socialCampaigns')}}</p>

      <div class="w-full flex flex-col md:flex-row mb-6">
        <a class="w-full mr-3" href="https://lc.oasisexperience.cc/auth/google/redirect">
          <div class="social-btn w-full h-11 bg-white flex-center border border-inputBorder cursor-pointer rounded-[16px] mb-3 md:mb-0">
              <img class="w-[15px] mr-3" src="@/assets/images/google-icon.svg" alt="">
              <span class="text-sm">{{t('auth.signInGoogle')}}</span>
          </div>
        </a>
        <a class="w-full" href="https://lc.oasisexperience.cc/auth/apple/redirect">
          <div class="social-btn w-full h-11 bg-white flex-center border border-inputBorder cursor-pointer rounded-[16px]">
              <img class="w-[15px] mr-3" src="@/assets/images/apple-black.svg" alt="">
              <span class="text-sm">{{t('auth.signInApple')}}</span>
          </div>
        </a>
      </div>

      <div class="w-full flex-center mb-6">
          <div class="w-1/3 h-px bg-cancelHover"></div>
          <div class="w-1/3 text-grey text-xs font-medium text-center">{{t('auth.orEmail')}}</div>
          <div class="w-1/3 h-px bg-cancelHover"></div>
      </div>

      <form
              action="#"
              class="flex flex-col justify-between w-full items-start mb-8"
              @submit.prevent="submitHandler"
      >
          <div class="w-full relative mb-4">
              <auth-input
                  v-model="form.email"
                  placeholder="Email"
                  :class="{'error-input': errors.email && errors.email.length}"
                  @input="errors.email = []"
              />
              <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                  <tippy
                      v-if="errors.email && errors.email.length"
                      theme="light"
                      placement="top-end"
                  >
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"/>
                      </svg>

                      <template #content>
                          <div class="custom-tooltip">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                      fill="#FF6939"/>
                              </svg>
                              <span>{{ errors.email[0] }}</span>
                          </div>
                      </template>
                  </tippy>
              </div>
          </div>
          <div class="w-full relative mb-4">
              <auth-input
                  v-model="form.password"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="t('auth.password')"
                  :class="{'error-input': errors.password && errors.password.length}"
                  @input="errors.password = []"
              >
                <template #icon>
                  <div v-if="errors.password && !errors.password.length" @click="showPassword = !showPassword">
                    <svg v-if="!showPassword" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 12C2 12 5 5 12 5C19 5 22 12 22 12C22 12 19 19 12 19C5 19 2 12 2 12Z" stroke="#2B2E34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#2B2E34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg v-else width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.88013 9.87988C9.58538 10.1545 9.34897 10.4857 9.185 10.8537C9.02104 11.2217 8.93287 11.619 8.92576 12.0218C8.91865 12.4246 8.99275 12.8247 9.14364 13.1983C9.29452 13.5718 9.5191 13.9112 9.80397 14.196C10.0888 14.4809 10.4282 14.7055 10.8017 14.8564C11.1753 15.0073 11.5754 15.0814 11.9782 15.0742C12.381 15.0671 12.7783 14.979 13.1463 14.815C13.5143 14.651 13.8455 14.4146 14.1201 14.1199" stroke="#2B2E34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M10.73 5.08C11.1513 5.02751 11.5754 5.00079 12 5C19 5 22 12 22 12C21.5529 12.9571 20.9922 13.8569 20.33 14.68" stroke="#2B2E34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M6.61 6.60986C4.62125 7.96449 3.02987 9.82512 2 11.9999C2 11.9999 5 18.9999 12 18.9999C13.9159 19.005 15.7908 18.445 17.39 17.3899" stroke="#2B2E34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M2 2L22 22" stroke="#2B2E34" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </template>
              </auth-input>
              <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                  <tippy
                      v-if="errors.password && errors.password.length"
                      theme="light"
                      placement="top-end"
                  >
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"/>
                      </svg>

                      <template #content>
                          <div class="custom-tooltip">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                      fill="#FF6939"/>
                              </svg>
                              <span>{{ errors.password[0] }}</span>
                          </div>
                      </template>
                  </tippy>
              </div>
          </div>
          <div class="w-full flex justify-end mb-8">
              <router-link
                      to="/forgot-password"
                      class="text-sm text-btnSubmit font-semibold"
              >{{t('auth.forgotPassword')}} ?</router-link>
          </div>

        <custom-button
            type="submit"
            :text="t('auth.signIn')"
            classes="mt-0 relative overflow-hidden animated-link footer-popup_btn magnetic bg-bg-orange oaut_btn"
            :isButton="true"
        />
      </form>

      <auth-form-footer
        :text="t('auth.notMember')"
        :link-name="t('auth.signUp')"
        link="/register"
      />

  </div>
</template>

<script setup>

import {reactive, ref} from "vue";
import AuthInput from "@/components/AuthInput.vue";
import AuthFormFooter from "@/components/AuthFormFooter.vue";
import AxiosIns from "@/services/apiService";
import {useI18n} from "vue-i18n";
import CustomButton from "@/components/CustomButton.vue";
import {Tippy} from "vue-tippy";

const {t} = useI18n()

const form = reactive({
    email: '',
    password: ''
})
const errors = ref({
    email: [],
    password: []
})

const showPassword = ref(false);

const submitHandler = async () => {
    const {data} = await AxiosIns.postData("/api/login", form);

    if (data.errors) {
        errors.value = data.errors
    } else {
        localStorage.setItem('exp', data.exp)
        localStorage.setItem('token', data.token)
        window.location.href = data.redirect_url
    }
}

</script>

<style scoped>
.social-btn {
    color: #5E6278;
}
.social-btn:hover {
    background-color: #F9F9F9;
    color: #009EF7;
}
</style>