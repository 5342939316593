<template>
    <section class="home-why-choose-us-wrap">
        <div class="container">
            <h2 class="home-why-choose-us-title">{{ t('whyChooseUs') }}</h2>
            <div class="flex flex-row justify-between flex-wrap items-center">
                <div
                    v-for="(item,i) in props.data"
                    :key="i"
                    class="home-why-choose-us-block"
                >
                    <div class="home-why-choose-us-svg" v-html="item.icon"></div>
                    <h4 class="home-why-choose-us-block-title">{{item.title}}</h4>
                    <p class="text-base text-title-color mt-4">{{item.text}}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>

import {useI18n} from "vue-i18n";

const { t } = useI18n()
const props = defineProps(['data'])
</script>
<style scoped>

</style>
  