<template>
    <HeaderNews :title='data.title'></HeaderNews>
    <div class="services-visa_wrap">
        <div class="container flex flex-row justify-between items-start">
            <div class="w-full lg:w-[70%] lg:mr-10">
                <div v-for="(item,i) in content" :key="i">
                    <div v-if="item.layout === 'block_text'" id="1">
                        <h3 class="text-title-color text-5xl font-extrabold mb-[24px] section_title leading-[1.2]">
                            {{item.attributes.title}}
                        </h3>
                        <div class="text-lg py-[24px] journal-text" v-html="item.attributes.content"></div>
                    </div>

                    <div v-if="item.layout === 'block_image'" id="2">
                        <img
                                v-for="(image, i) in item.attributes"
                                :key="i"
                                :src="image.original_url"
                                class="w-full h-auto max-h-[400px] rounded-[24px] mb-[58px] object-cover"
                                alt=""
                        >
                    </div>

                    <div id="3" v-if="item.layout === 'block_gallery'" class="flex flex-row justify-between items-start mb-20 mt-[26px] flex-wrap">
                        <div
                                v-for="(image, i) in Object.values(item.attributes)"
                                :key="i"
                                class="sm:w-[calc(100%/2-16px)] mt-[32px] relative rounded-[24px] cursor-pointer"
                                @mouseover="mouseOverHandler('about-img_magnetic', 'increase', i === lastElement(item))"
                                @mouseout="mouseOutHandler(i === lastElement(item))"
                                @click="showMultiple(i === lastElement(item), Object.values(item.attributes))"
                        >

                            <img :src="image.original_url"
                                 class="w-full h-[240px] rounded-[24px] object-cover" alt="">
                        </div>
                    </div>

                    <div id="4" v-if="item.layout === 'block_video'" class="rounded-[24px] mt-[58px] mb-20">
                        <div v-if="showVideo !== item.attributes.video" class="w-full h-auto sm:h-[400px] rounded-[24px] relative">
                            <img
                                    v-if="item.attributes.thumbnail"
                                    :src="item.attributes.thumbnail"
                                    class="w-full h-full rounded-[24px]"
                            />
                            <img
                                    v-else
                                    :src="`//img.youtube.com/vi/${item.attributes.video}/maxresdefault.jpg`"
                                    class="w-full h-full rounded-[24px] object-cover"
                            />
                            <img
                                    src="@/assets/images/play.png"
                                    class="absolute-center w-[100px] cursor-pointer"
                                    @click="playVideo(item.attributes.video)"
                            />
                        </div>
                        <iframe
                                v-if="showVideo === item.attributes.video"
                                class="w-full h-[400px] rounded-[24px]"
                                :src="`https://www.youtube.com/embed/${item.attributes.video}?autoplay=1`"
                                title="YouTube video player" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen>
                        </iframe>
                    </div>
                </div>

                <services-deadline id="4" :deadline-data="extensions" />
            </div>
<!--          <div class="w-[25%] static xl:sticky top-[120px] ring-0 hidden lg:block">-->
<!--            <h5-->
<!--                v-for="item in sections"-->
<!--                :key="item.key"-->
<!--                class="consulting-sidebar cursor-pointer duration-500"-->
<!--                :class="{sidebar_active: item.key == currentSection}">-->
<!--              <img-->
<!--                  src="../../../public/images/sidebar-border.svg"-->
<!--                  class="hidden mr-[12px]"-->
<!--                  alt=""-->
<!--              >-->
<!--              {{ item.text }}-->
<!--            </h5>-->
<!--          </div>-->
        </div>
    </div>
    <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    ></vue-easy-lightbox>
</template>

<script setup>
import HeaderNews from '../../components/HeaderNews.vue';
import ServicesDeadline from "@/components/services/ServicesDeadline.vue";
import VueEasyLightbox from 'vue-easy-lightbox'

document.querySelector('body').classList.add('bg-white');
import {onMounted, ref, watch} from "vue";

// let sections = [
//     {key: 1, text: 'Section 1'},
//     {key: 2, text: 'Section 2'},
//     {key: 3, text: 'Section 3'},
//     {key: 4, text: 'Section 4'},
// ]

let index = ref(0)
let currentSection = ref(0)
let visible = ref(false)
let imgs = ref([])
let extensions = ref([])
const { t } = useI18n()

import { useStore } from 'vuex'
import AxiosIns from "@/services/apiService";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const store = useStore()
const router = useRouter();
const route = useRoute();

const show = () => {
    store.commit('setIsNewsModal', true)
    visible.value = true
}
const handleHide = () => {
    store.commit('setIsNewsModal', '')
    visible.value = false
}

const showMultiple = (showCondition, images) => {
    if(showCondition) {
        imgs.value = []
        images.map((item, i) => {
            let img = {
                title: `Image ${i+1}`,
                src: item.original_url
            }
            imgs.value.push(img)
        })

        index.value = 0 // index of imgList
        show()
    }

}
const lastElement = (item) => {
    return Object.values(item.attributes).length - 1
}
const mouseOverHandler = (name, text, showCondition) => {
    if(showCondition) {
        store.commit('setCursorInner', t(text))
        store.commit('setCursorClass', name)
    }
};
const mouseOutHandler = (showCondition) => {
    if(showCondition) {
        store.commit('setCursorInner', "")
        store.commit('setCursorClass', "")
    }
};
let data = ref([])
let content = ref([])
// let latestNews = ref([])

// const dateFormat = (date) => {
//     return date.split('T')[0]
// }

const getData = async () => {
    const response = await AxiosIns.getData(`/api/pages/${route.params.slug}`);
    data.value = response.data;
    extensions.value = response.data.extensions
    content.value = response.data.flexible_content
}

const playVideo = (video) => {
    showVideo.value = video
}

let showVideo = ref('')

// const getAllData = async () => {
//     const response = await AxiosIns.getData("/api/journals");
//     const data = response.data
//
//     data.sort((a,b) => {
//         return new Date(b.created_at) - new Date(a.created_at);
//     });
//
//     latestNews.value = data.splice(0, 3)
// }

watch(
    () => route.params.slug,
    async () => {
        await loadPage()
    }
)

onMounted(async () => {
    await loadPage()
})

const loadPage = async () => {
    await router.isReady();
    await getData();
    // await getAllData();

    window.scrollTo(0, 0)

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                currentSection.value = parseInt(entry.target.getAttribute('id'))
            }
        })
    })
    document.querySelectorAll('.section_title').forEach((section) => {
        observer.observe(section)
    })
}
</script>