<template>
    <HeaderOtherPages :title="t('faq')"></HeaderOtherPages>
    <div class="questions-wrap">
      <div v-for="(category, index) in categories" :key="category[0].category.name" :class="`container ${index !== 0 ? 'mt-[120px]' : ''}`">
            <h3 class="text-title-color text-5xl font-semibold">{{ category[0].category.name }}</h3>
            <app-accordion v-for="(item ,i) in category" :key="i" class="mt-[18px]">
                <template v-slot:title>
                    <h4 class="home-questions-text">
                        {{item.question}}</h4>
                </template>
                <template v-slot:content>
                    <p class="text-base lg:text-lg mt-[20px] md:mt-0">
                        {{item.answer}}
                    </p>
                </template>
            </app-accordion>
        </div>
    </div>
</template>

<script setup>
import HeaderOtherPages from "@/components/Ui/HeaderOtherPages.vue";
import AppAccordion from "@/components/Ui/QuestionsAccordionBlock.vue"

// отправляем запрос получаем ответ
import AxiosIns from "@/services/apiService"
import {onMounted, ref} from "vue";
import {useI18n} from "vue-i18n";

const { t } = useI18n()
onMounted(() => {
  document.querySelector("body").classList.add("bg-white");
});

const faq = ref([]);
const categories = ref([]);

(async () => {
  const response = await AxiosIns.getData("/api/questions");
  faq.value = response.data;
  categories.value = faq.value
      .map((el) => {
        return el.category_id;
      })
      .filter((el, index, array) => {
        return array.indexOf(el) === index;
      })
      .map((el) => {
        return faq.value.filter((item) => item.category_id === el);
      });
})();


</script>
