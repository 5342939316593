<template>
    <div class="flex flex-col items-start">
        <p class="text-2xl text-white mb-[16px] uppercase">Contact us</p>
        <div class="flex flex-row justify-between items-start flex-wrap">
            <div
                    v-for="(item ,i) in contactsData"
                    :key="i"
                    class="flex flex-col items-start"
                    :class="{'mr-[40px] mb-[20px]': i === 0}"
            >
                <a
                        :href="`mailto:${item.email}`"
                        class="footer-email group"
                >
                    {{ item.email }}
                    <div class="footer-text_underline duration-900"></div>
                </a>
                <a
                        :href="`tel:${item.phone}`"
                        class="footer-phone group"
                >
                    {{ item.phone }}
                    <div class="footer-text_underline duration-900"></div>
                </a>
                <p
                        v-html="item.text"
                        class="text-[16px] text-white opacity-60 font-semibold">
                </p>
            </div>
        </div>
    </div>
</template>

<script setup>
const contactsData = [
    {email: 'info@oasisexperience.cc', phone: '+62 912 123-1211', text: 'Riia st. 24a, 51010<br>Indonesia,\n' +
            '                                    Bali'},
    {email: 'info@oasisexperience.cc', phone: '+62 912 123-1211', text: 'Riia st. 24a, 51010<br>Indonesia,\n' +
            '                                    Bali'},
]
</script>