<template>
    <div>
        <button
                @click="toggleAccordion()"
                class="flex flex-row items-start space-x-3 justify-between w-full py-[40px] border-b-[1px] border-accordionBorderColor"
                :class="{'isOpen': isOpen}"
                :aria-expanded="isOpen"
                :aria-controls="`collapse${_uid}`"
        >
            <slot name="title"/>

            <div ref="buttonWrapper" id="button-wrapper_question">
                <div id="button-circle_question"></div>
                <div id="menu-burger_question">
                    <svg
                        class="w-[20px] sm:w-[27px] transition-all duration-200 transform mt-[15px]"
                        :class="{
                            'rotate-180': isOpen,
                            'rotate-0': !isOpen,
                          }"
                        fill="none"
                        stroke="#000"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 10"
                        aria-hidden="true"
                    >
                    <path
                        d="M15 1.2l-7 7-7-7"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    </svg>
                </div>

            </div>

        </button>

        <div v-show="isOpen" :id="`collapse${_uid}`" class="pt-[32px] w-[100%] lg:w-[75%] text-left">
            <div class="flex flex-col md:flex-row items-start">
                <div class="accordion-answer focus:ring-blue-500 focus:border-blue-500">
                    <p>{{t('answer')}}</p>
                </div>
                <slot name="content"/>
            </div>
        </div>
    </div>
</template>

<script setup>
import {defineProps, onMounted, onUnmounted, ref} from "vue";
import {gsap, Power2} from "gsap";
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const props = defineProps({
    opened: {
        type: String,
        default: ''
    }
})

let isOpen = ref(false)
const buttonWrapper = ref(null);
let width = ref(window.innerWidth)
const toggleAccordion = () => isOpen.value = !isOpen.value
onMounted(() => {
    if (props.opened === 'block') {
        isOpen.value = true
    }
    window.addEventListener("resize", resizeHandler);
    initMagneticButton();
})

onUnmounted(() => {
    window.removeEventListener("resize", resizeHandler);
})

const resizeHandler = () => {
    width.value = window.innerWidth
}

const initMagneticButton = () => {
    if(width.value < 768) return;


    const buttonCircle = buttonWrapper.value.querySelector('#button-circle_question');
    const menuBurger = buttonWrapper.value.querySelector('#menu-burger_question');

    buttonWrapper.value.addEventListener('mouseenter', () => {
        gsap.to(buttonCircle, 0.3, {scale: 1.3, backgroundColor: "#000"});
        buttonWrapper.value.classList.add('hover');
    });

    buttonWrapper.value.addEventListener('mouseleave', () => {
        gsap.to([buttonCircle, menuBurger], 0.3, {
            scale: 1,
            x: 0,
            y: 0
        });
        gsap.to(buttonCircle, 0.3, {backgroundColor: "#fff"});
        buttonWrapper.value.classList.remove('hover');
    });

    buttonWrapper.value.addEventListener('mousemove', (e) => {
        magneticEffect(buttonWrapper.value, e, buttonCircle, 30);
        magneticEffect(buttonWrapper.value, e, menuBurger, 30);
    });

};

const magneticEffect = (element, e, target, movement) => {
    const boundingRect = element.getBoundingClientRect();
    const relX = e.pageX - boundingRect.left;
    const relY = e.pageY - boundingRect.top;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    gsap.to(target, 0.3, {
        x: ((relX - boundingRect.width / 2) / boundingRect.width) * movement,
        y:
            ((relY - boundingRect.height / 2 - scrollTop) / boundingRect.width) *
            movement,
        ease: Power2.easeOut
    });
};

</script>

<style>

@media (min-width: 768px) {
    #button-wrapper_question {
        width: 60px;
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
    }

    #button-circle_question {
        width: 40px;
        height: 40px;
        border-radius: 32px;
        box-sizing: border-box;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -12px;
        margin-left: -18px;
        cursor: pointer;
        z-index: 5;
    }

    .isOpen #button-circle_question {
        margin-top: -6px;
    }

    #menu-burger_question {
        width: 24px;
        height: 22px;
        position: relative;
        margin: 0 auto;
        pointer-events: initial;
        z-index: 999;
    }

    #button-wrapper_question:hover #menu-burger_question svg {
        stroke: #fff;
    }
}
</style>