<template>
  <div class="w-full flex mb-2">
    <div
        v-for="(item,i) in lines"
        :key="i"
        class="h-[5px] rounded bg-inputBorder w-full mr-2"
        :class="{
                  'mr-0': i === lines.length - 1,
                  '!bg-green': item.check
              }"
    ></div>
  </div>
</template>

<script setup>
import {ref, watch} from "vue";

const props = defineProps({
  password: {
    type: String,
    default: ''
  }
})

const lines = ref([
  {check: false}, // длина пароля
  {check: false}, // наличие цифры
  {check: false}  // наличие заглавной буквы
])

watch(
    () => props.password,
    () => {
        // проверка длины пароля
        const length = props.password.length >= 8
        // проверка на наличие цифры
        const numbers = /\d/.test(props.password)
        // проверка на наличие заглавной буквы
        const letter = /[A-Z]/.test(props.password)

        const conditions = [length, numbers, letter].filter(item => item === true)

        lines.value.map((item, i) => {
                item.check = i < conditions.length
        })

    }
)
</script>

<style scoped>

</style>



