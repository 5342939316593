<template>
    <section class="home-result_wrap second_block">
        <div class="home-result">
            <div
                v-for="(item ,i) in resultData"
                :key="i"
                class="home-result_block"
                :class="{'mb-[65px] lg:mb-0': i < resultData.length - 1}"
            >
                <h2 class="home-result_title">{{ item.title }}</h2>
                <p class="home-result_text">{{ t(item.text) }}</p>
            </div>
        </div>
    </section>
</template>

<script setup>

import {useI18n} from "vue-i18n";

const { t } = useI18n();

const resultData = [
    {title: '11K+', text: 'homeResult.customerSatisfaction'},
    {title: '99%', text: 'homeResult.customerSatisfaction'},
    {title: '12 323+', text: 'homeResult.clients'},
    {title: '24/7', text: 'homeResult.aroundTheClock'},
]

</script>
<style scoped>

</style>
  