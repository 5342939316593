import { createStore } from 'vuex'

export default createStore({

    state () {
        return {
            showScrolledMenu: false,
            cursorInner: '',
            cursorClass: '',
            cursorWrapperClass: '',
            videoSrc: '',
            isNewsModal: '',
            user: {}
        }
    },

    getters: {},

    mutations: {
        setShowScrolledMenu(state, value) {
            state.showScrolledMenu = value
        },
        setCursorInner(state, value) {
            state.cursorInner = value
        },
        setCursorClass(state, value) {
            state.cursorClass = value
        },
        setCursorWrapperClass(state, value) {
            state.cursorWrapperClass = value
        },
        setVideoSrc(state, value) {
            state.videoSrc = value
        },
        setIsNewsModal(state, value) {
            state.isNewsModal = value
        },
        setUser(state, value) {
            state.user = value
        },
    },

    actions: {}
})