<template>
    <HeaderOtherPages class="pt-16" :title='currentData.title'></HeaderOtherPages>
    <div class="services-visa_wrap">
        <div class="container flex flex-row justify-between items-start">
            <div
                class="w-[30%] static xl:sticky top-[120px] left-0 hidden lg:block"
                :class="{'top-[160px]': !showScrolledMenu}"
            >
                <h5
                        v-for="(item, i) in servicesData" :key="i"
                        class="services-visa duration-500"
                        :class="{sidebar_active: item.key === currentSection}"
                >
                    <img src="../../../public/images/sidebar-border.svg" class="hidden mr-[12px]" alt="">
                    {{ t(item.text) }}
                </h5>
            </div>
            <div class="w-full lg:w-[70%] lg:pl-8">
                <h3 class="text-title-color text-5xl font-extrabold mb-[24px] section_title" id="1">
                    {{currentData.title}}
                </h3>
                <div v-html="currentData.description" class="text-lg current-service_description">

                </div>

                <services-deadline :deadline-data="extensions" />

                <h3 class="text-title-color text-5xl font-semibold mt-[120px] section_title" id="2">
                    {{t('service.whatPurpose')}}
                </h3>
                <div
                        v-for="(item, i) in goals"
                        :key="i"
                        class="services-visa_purpose"
                >
                    <img v-if="item.icon === '+'" src="../../../public/images/service-check.png" class="mr-[32px]" alt="">
                    <img v-else src="../../../public/images/service-cross.png" class="mr-[32px]" alt="">
                    <h4 class="text-title-color font-[500] text-[32px]">{{ item.text }}</h4>
                </div>

                <h3 class="text-title-color text-5xl font-semibold mt-[120px] section_title" id="3">
                    {{t('service.documents')}}
                </h3>
                <div class="flex flex-row flex-wrap justify-between md:mt-[48px]">
                    <div
                            v-for="(document, i) in documents"
                            :key="i"
                            class="services-visa_document"
                            :class="{ 'md:mt-0' : i === 0 || i === 1}"
                    >
                        <span v-html="document.icon"></span>
                        <p class="text-xl	text-title-color text-left mt-[20px]">
                            {{ document.text }}
                        </p>
                    </div>
                </div>

                <h3 class="text-title-color text-5xl font-extrabold mt-[120px] mb-[48px] section_title" id="4">
                    {{t('service.stagesObtaining')}}
                </h3>
                <div
                        v-for="(item, i) in stages"
                        :key="i"
                        class="services-visa_stages"
                >
                    <div class="w-[30%]">
                        <h5 class="font-semibold text-2xl text-title-color">
                            {{i <=9 ? 0 : ''}}{{ i+1 }}
                        </h5>
                    </div>
                    <div class="w-[70%]">
                        <h5 class="font-semibold text-2xl text-title-color">
                            {{ item.title }}
                        </h5>
                        <p class="text-base mt-[16px]">
                            {{ item.description }}
                        </p>
                        <a
                            v-if="i === stages.length - 1"
                            href="#"
                            class="services-visa_stages_link"
                        >
                            {{t('service.submitApplication')}}
                        </a>
                    </div>
                </div>

                <h3 class="text-title-color text-5xl font-extrabold mt-[120px] mb-[48px] section_title" id="5">
                    {{t('service.rates')}}
                </h3>
                <visa-rates :rates="rates"/>
            </div>
        </div>
       <other-services :services="randomServices" />
    </div>
</template>

<script setup>
import HeaderOtherPages from "@/components/Ui/HeaderOtherPages.vue";
import VisaRates from "@/components/services/VisaRates.vue";
import OtherServices from "@/components/services/OtherServices.vue";
import ServicesDeadline from "@/components/services/ServicesDeadline.vue";

import {computed, onMounted, ref, watch} from "vue";
import { useStore } from 'vuex'
import AxiosIns from "@/services/apiService";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const store = useStore()
document.querySelector('body').classList.add('bg-white');
const showScrolledMenu = computed(() => store.state.showScrolledMenu)
let currentSection = ref(0);

const servicesData = ref([
    {key: 1, text: 'service.generalInformation' },
    {key: 2, text: 'service.important' },
    {key: 3, text: 'service.documents' },
    {key: 4, text: 'service.stages' },
    {key: 5, text: 'service.rates' },
]);

const router = useRouter();
const route = useRoute();
let currentData = ref({});
let documents = ref([]);
let stages = ref([]);
let extensions = ref([]);
let goals = ref([]);
let randomServices = ref([]);
let rates = ref([]);

const getData = async () => {
    const {data} = await AxiosIns.getData(`/api/services/${route.params.id}`);
    currentData.value = data
    documents.value = data.documents
    stages.value = data.stages
    extensions.value = data.extensions
    goals.value = data.goals
    rates.value = data.visa?.rates
}

const getServices = async () => {
    const {data} = await AxiosIns.getData("/api/services");
    const shuffled = [...data.filter(item => item.id !== parseInt(route.params.id))].sort(() => 0.5 - Math.random());
    randomServices.value = shuffled.slice(0,2)
}

watch(
    () => route.params.id,
    async () => {
        await loadPage()
    }
)

const loadPage = async () => {
    await router.isReady();
    await getData();
    await getServices();

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                currentSection.value = parseInt(entry.target.getAttribute('id'))
            }
        })
    })
    document.querySelectorAll('.section_title').forEach((section) => {
        observer.observe(section)
    })
}
onMounted(async () => {
    await loadPage()

})
</script>
<style>
.current-service_description p {
    padding-bottom: 24px;
}
</style>