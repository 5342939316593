<template>
    <div class="home-reviews_wrap">
        <div class="container relative">
            <div class="flex flex-col lg:flex-row justify-between items-center">
                <h2 class="home-reviews_title">{{ t('reviews') }}</h2>

                <custom-button
                    :is-link="true"
                    linkTo="/reviews"
                    width="190px"
                    :text="t('allReviews')"
                    classes="home-reviews_link magnetic"
                />
            </div>
            <swiper
                    ref="mySwiper"
                    class="pb-[100px] mt-[24px] sm:mt-[58px]"
                    :slides-per-view="1"
                    navigation
                    :space-between="32"
                    :modules="modules"
                    :breakpoints="swiperOptions.breakpoints"
                    :speed="600"
                    :pagination="{ clickable: true }"
            >
                <swiper-slide v-for="(item, i) in reviewsData" :key="i">
                    <review-card :item="item" @openModal="openModal" />
                </swiper-slide>

            </swiper>
        </div>
    </div>
    <Popup class="popup-wrap" :class="{ hidden: !showModal }">
        <div class="home-reviews-popup">
            <svg class="home-reviews-popup_close" @click="closeModal"
                 width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="28" cy="28" r="27.25" stroke="#E8E7EA" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.3421 21.3418C21.5609 21.1231 21.8575 21.0003 22.1669 21.0003C22.4763 21.0003 22.773 21.1231 22.9917 21.3418L28.0002 26.3503L33.0087 21.3418C33.1164 21.2304 33.2451 21.1415 33.3874 21.0804C33.5298 21.0192 33.6829 20.9871 33.8378 20.9857C33.9927 20.9844 34.1463 21.0139 34.2897 21.0725C34.4331 21.1312 34.5633 21.2178 34.6729 21.3274C34.7824 21.4369 34.869 21.5672 34.9277 21.7106C34.9864 21.8539 35.0159 22.0076 35.0145 22.1625C35.0132 22.3174 34.981 22.4705 34.9199 22.6128C34.8587 22.7551 34.7698 22.8839 34.6584 22.9915L29.6499 28L34.6584 33.0085C34.8709 33.2285 34.9885 33.5232 34.9859 33.8291C34.9832 34.135 34.8605 34.4276 34.6442 34.644C34.4279 34.8603 34.1353 34.983 33.8294 34.9856C33.5235 34.9883 33.2288 34.8707 33.0087 34.6582L28.0002 29.6497L22.9917 34.6582C22.7717 34.8707 22.477 34.9883 22.1711 34.9856C21.8652 34.983 21.5726 34.8603 21.3563 34.644C21.14 34.4276 21.0173 34.135 21.0146 33.8291C21.012 33.5232 21.1296 33.2285 21.3421 33.0085L26.3506 28L21.3421 22.9915C21.1234 22.7727 21.0005 22.476 21.0005 22.1667C21.0005 21.8573 21.1234 21.5606 21.3421 21.3418Z"
                      fill="#21242C"/>
            </svg>

            <p class="text-title-color text-2xl text-left font-[600]">{{ review.name }}</p>
            <p class="text-reviewsOrange text-[14px] mt-[11px] text-left font-[400]">{{ review.user_name }}</p>
            <p class="text-base text-title-color mt-[25px]">{{ review.longBody }}</p>
        </div>
    </Popup>
</template>

<script setup>
// Import Swiper Vue.js components
import {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
import ReviewCard from "@/components/ReviewCard.vue";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {onMounted, ref} from "vue";
import CustomButton from "@/components/CustomButton.vue";
import AxiosIns from "@/services/apiService";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";


const { t } = useI18n()

const modules =  [Navigation, Pagination]
const swiperOptions = {
    breakpoints: {
        1024: {
            slidesPerView: 2,
        }
    }
}
let review = {
    text: '',
    name: '',
    senderName: '',
}

const reviewsData = ref([])
let showModal = ref(false);
const store = useStore()

const openModal = (data) => {
    showModal.value = true
    store.commit('setIsNewsModal', true)
    Object.assign(review, data)
};
const closeModal = () => {
    store.commit('setIsNewsModal', '')
    showModal.value = false

};

onMounted( async () => {
    const {data} = await AxiosIns.getData("/api/reviews");
    reviewsData.value = data
    reviewsData.value.map((item) => {
        item.longBody = item.body;
        const words = item.body.split(' ')
        if(words.length > 20) {
            item.shortBody = words.splice(0, 20).join(' ')
        }
    })
})
</script>
<style scoped>

</style>
  