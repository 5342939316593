<template>
    <AuthLayout v-if="isAuthPages">
        <router-view></router-view>
    </AuthLayout>
  <SocialLogin v-else-if="socialAuth">
    <router-view></router-view>
  </SocialLogin>
  <BaseLayout v-else>
    <MagneticCursor />
    <router-view></router-view>
  </BaseLayout>
</template>

<script setup>
import BaseLayout from './components/Layout/BaseLayout.vue';
import AuthLayout from "@/components/Layout/AuthLayout.vue";
import MagneticCursor from './components/MagneticCursor.vue';
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import SocialLogin from "@/views/SocialLogin.vue";
import AxiosIns from "@/services/apiService";
import {useStore} from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore()

let isAuthPages = ref(false)
let socialAuth = ref(false)

onMounted(async () => {
    await router.isReady();
    isAuthPages.value = route.name === 'login' ||
        route.name === 'register' ||
        route.name === 'forgot-password' || route.name === 'new-password'
    socialAuth.value = route.name === 'social-login'

  if(localStorage.getItem('token')) {
    const response = await AxiosIns.getData("/api/me", {headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }});
    store.commit('setUser', response)
  }

})

</script>

<style>
</style>
