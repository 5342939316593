<template>
    <section class="pt-[110px] pb-[100px] relative">
        <!-- Top header start -->

        <!-- Top header end -->
        <img src="../../../../public/images/HomeHeaderPattern1.svg" class="z-0 absolute right-0 top-0 hidden lg:block"
             alt="">
        <img src="../../../../public/images/HomeHeaderPattern2.svg" class="z-0 absolute left-0 bottom-0 hidden lg:block"
             alt="">
        <img src="../../../../public/images/HomeHeaderPatternMobile1.svg"
             class="z-10 absolute right-0 top-0 block lg:hidden"
             alt="">

        <div class="w-full flex justify-between items-end mb-[43px] lg:mb-0 p-[20px] lg:p-0 relative"
             :class="{ hidden: menuShow }">
        </div>
        <div class="lg:mt-[150px] 2xl:mt-[240px] container pb-0 sm:pb-[125px] ">
            <div class="screen_item mb-4 md:mb-[64px] z-[20]">
                <div class="header-home_sub mb-[12px] text-[24px] font-normal z-[20] relative w-fit">
                  {{ t('home.heroSubTitle') }}
                </div>
                <div class="desktop-plane-text header-home_links flex">
                  <div
                      id="video-circle"
                      class="header-home_link z-[20] relative"
                      @mouseover="mouseOverHandler($event, $event.target, 'video-animation_xl', '/videos/animation.mp4', '!z-10')"
                      @mouseout="mouseOutHandler('video-animation_xl')"
                  >
                    <div id="video-circle"></div>
                    {{ t('home.heroVisa') }}
                  </div>

                  <div
                      id="video-circle"
                      class="header-home_link z-[20] relative"
                      @mouseover="mouseOverHandler($event, $event.target, 'video-animation_xl', '/videos/animation1.mp4', '!z-10')"
                      @mouseout="mouseOutHandler('video-animation_xl')"
                  >
                    <div id="video-circle"></div>
                    {{ t('home.heroKitas') }}
                  </div>

                  <div
                      id="video-circle"
                      class="header-home_link z-[20] relative"
                      @mouseover="mouseOverHandler($event, $event.target, 'video-animation_xl', '/videos/animation2.mp4', '!z-10')"
                      @mouseout="mouseOutHandler('video-animation_xl')"
                  >
                    {{ t('home.heroBussines') }}
                  </div>

                </div>
                <h1 class="desktop-plane-text header-home_title font-sui text-[88px] leading-[102px] z-[20] relative font-medium">{{ t('home.heroInIndonesia') }}.</h1>
                <h1 class="mobile-plane-text">
                    <span>
                        {{ t('home.heroVisa') }}, {{ t('home.heroKitas') }} {{ t('home.heroBussines') }}</span>
                    <span> {{ t('home.heroInIndonesia') }} .</span>
                </h1>
            </div>
            <div class="flex flex-col-reverse md:flex-row justify-between items-start md:items-end">
                <button type="button"
                        class="cursor-pointer flex-center w-[32px] h-[32px] rounded-[11px] bg-blue mt-10"
                        @click="scrollToElement('second_block')">
                    <svg width="18" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                                d="M17.7071 0.292894C18.0976 0.683418 18.0976 1.31658 17.7071 1.70711L9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L0.292893 1.70711C-0.0976312 1.31658 -0.0976311 0.683417 0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L9 7.58579L16.2929 0.292894C16.6834 -0.0976303 17.3166 -0.0976303 17.7071 0.292894Z"
                                fill="white"/>
                    </svg>
                </button>
                <p class="text-lg md:w-[526px]">{{ t('home.heroDescriptions') }}</p>
            </div>
        </div>
    </section>
</template>

<script setup>
import { useStore } from "vuex";
import { gsap } from "gsap";
import { onBeforeUnmount, onMounted, ref } from "vue";
import {useI18n} from "vue-i18n";

const store = useStore();
const { t } = useI18n()

let scrollPosition = ref(window.scrollY);
let showSticky = ref(true);
let menuShow = ref(false);
let windowTop = ref(window.top.scrollY);

const scrollToElement = (blockName) => {
  const element = document.querySelector("." + blockName);
  const top = element.offsetTop - 100;
  window.scrollTo({
    top: top,
    left: 0,
    behavior: "smooth",
  });
};

const onScroll = () => {
  scrollPosition.value = window.scrollY;
  showSticky.value = scrollPosition.value < windowTop.value;
  windowTop.value = scrollPosition.value;
};

const handleMouseMove = (event, videoCenter, videoElement) => {
  const video = this.$refs[videoElement];
  const distanceX = Math.abs(event.clientX - videoCenter.x);
  const distanceY = Math.abs(event.clientY - videoCenter.y);
  const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

  // Определить область, в которой размер элемента увеличивается
  const zoomArea = video.offsetWidth * 0.3;

  // Определить коэффициент масштабирования элемента на основе расстояния
  const scale = 1 + (zoomArea - distance) / zoomArea * 0.5;
  const clampedScale = Math.max(0.5, Math.min(scale, 1.5));

  gsap.to(video, { scale: clampedScale, duration: 0.5, ease: "power2.out" });
};

const mouseOverHandler = (event, linkElement, videoElement, src, wrapperClass = '') => {
  store.commit("setVideoSrc", src);
  store.commit("setCursorClass", videoElement);
  store.commit("setCursorWrapperClass", wrapperClass);

  const video = this.$refs[videoElement];
  const videoRect = video.getBoundingClientRect();
  const videoCenter = {
    x: videoRect.left + videoRect.width / 2,
    y: videoRect.top + videoRect.height / 2,
  };

  document.addEventListener("mousemove", (event) => handleMouseMove(event, videoCenter, videoElement));
};




const mouseOutHandler = (videoElement) => {
  store.commit("setCursorInner", "");
  store.commit("setCursorClass", "");
  store.commit("setCursorWrapperClass", "");
  store.commit("setVideoSrc", "");
  if (videoElement) {
    gsap.to(this.$refs[videoElement], { scale: 0, duration: 0.5 });
  }
  document.removeEventListener("mousemove", handleMouseMove);
};


onMounted(() => {
  window.addEventListener("scroll", onScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener("scroll", onScroll);
});
</script>

<style scoped>
.mobile-plane-text {
    display: none;
    font-style: normal;
    font-weight: 700;
    font-size: 46px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #2B2E34;
}

#button-circle {
  position: relative
}

#button-circle:before {
  content: "";
  display: block;
  position: absolute;
  top: -30vh;
  bottom: -30vh;
  left: 0;
  right: 0
}
.mobile-plane-text span:first-child {
    text-decoration-line: underline;
}
@media(max-width: 768px) {
    .plane-img {
        width: 210px;
    }
    .header-home_sub {
        display: none;
    }
    .mobile-plane-text {
        display: block;
    }
    .desktop-plane-text {
        display: none;
    }
}
</style>
