<template>
    <HeaderOtherPages :title="t('aboutUs')"></HeaderOtherPages>
    <div class="inline-block w-full relative">
        <AboutUsBlock
            type="about_page"
            :data="about"
        ></AboutUsBlock>
        <div class="container pb-[112px] sm:pb-[240px]">
            <h3 class="about-us_title mb-[24px]">{{generalData.title}}</h3>
            <div class="flex-col-between-center md:flex-row">
                <div class="text-base text-title-color general-data_content" v-html="generalData.content"></div>
                <img src="../../public/images/line.svg" class="mx-[72px] hidden md:block" alt="">
                <div v-html="generalData.info" class="text-base mt-[16px] text-about_gray_text"></div>
            </div>
            <h3 class="about-us_title mt-[65px] mb-[48px]">{{ t('about.ourServices') }}</h3>
            <div class="flex flex-col lg:flex-row justify-between lg:items-center lg:flex-wrap">
                <div
                        v-for="(item, i) in services"
                        :key="i"
                        class="about-us_service"
                >
                    <div v-html="item.icon" class="about-us_services-img" ></div>
                    <h4 class="about-us_services-text">{{ item.text }}</h4>
                </div>
            </div>
        </div>
    </div>
    <WhyChooseUsBlock :data="about.why_choose_us"></WhyChooseUsBlock>
    <div class="py-[56px] sm:py-[120px] container">
        <h2 class="about-slide_title">{{ t('about.team') }}</h2>
        <swiper
                :slides-per-view="1"
                :space-between="20"
                :modules="modules"
                :speed="600"
                :breakpoints="swiperOptions.breakpoints"
                :pagination="{ clickable: true }"
                ref="mySwiper"
                class="pb-[56px]"
        >
            <swiper-slide class="slide-1" v-for="(item, i) in team" :key="i">
                <div>
                    <img :src="item.avatar"
                         class="about-slide_items" alt="">
                    <div class="flex items-center justify-between">
                        <div>
                            <p class="mb-1 text-title-color text-3xl font-bold">{{item.name}}</p>
                            <p class="text-title-color text-base font-normal">{{item.position}}</p>
                        </div>
                        <div class="social"></div>
                    </div>
                </div>
            </swiper-slide>

        </swiper>
    </div>
  <div class="mt-12">
        <v-results-block></v-results-block>
    </div>
    <div class="about-us_block_wrap">
        <NewsBlock></NewsBlock>
    </div>
</template>

<script setup>
import HeaderOtherPages from '../components/Ui/HeaderOtherPages.vue';
import AboutUsBlock from '../components/SectionsPage/Home/VAboutUsBlock.vue';
import WhyChooseUsBlock from '../components/SectionsPage/Home/VWhyChooseUsBlock.vue';
import NewsBlock from '../components/SectionsPage/Home/VNewsBlock.vue';
import VResultsBlock from "@/components/SectionsPage/Home/VResultsBlock.vue";
// Import Swiper Vue.js components
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import {onMounted, ref} from "vue";
import AxiosIns from "@/services/apiService";
import {useI18n} from "vue-i18n";

const { t } = useI18n()
let about = ref({})
let generalData = ref({})
let services = ref([])
let team = ref([])

const swiperOptions = {
    breakpoints: {
        768: {
            pagination: true,
            slidesPerView: 3,
            speed: 0
        },
    }
}

const modules = [Pagination]


onMounted(async () => {
    document.querySelector('body').classList.add('bg-white');
    const {data} = await AxiosIns.getData("/api/about-us");
    about.value = data
    generalData.value = data.general_information
    services.value = data.services
    team.value = data.team
})
</script>

<style>
.general-data_content p {
    margin-top: 16px;
}
</style>
      