<template>
    <footer
        class="contacts-footer_wrap contactPageForm">
        <div class="container pt-[220px] z-10">
            <div class="flex flex-col md:flex-row justify-between items-start">
                <div class="flex flex-col justify-start items-start md:pr-[143px]">
                    <h3 class="text-[48px] font-semibold text-white mb-[16px]">
                      {{ t('footer.anyQuestions') }}<br>{{ t('footer.weWillContactYou') }}
                    </h3>
                    <p class="text-base text-white">
                      {{ t('footer.descAnyQuestions') }}
                    </p>
                </div>
                <contacts-form />
            </div>
            <div class="flex flex-row justify-between items-center mt-[145px] flex-wrap">
                <div class="flex-row-start flex-wrap mb-[30px]">
                    <div class="flex flex-col items-start mr-[88px] mb-[20px]">
                        <p class="text-2xl text-white mb-[12px]">oasisexperience.cc</p>
                        <p class="text-[16px] text-white opacity-60 poppins-font">Indonesia, Bali</p>
                    </div>
                    <div class="flex flex-col items-start mr-[88px]">
                        <p class="text-2xl text-white mb-[16px]">Navigations</p>
                        <div class="flex flex-row justify-between items-start flex-wrap">
                            <div class="flex flex-col items-start mb-[20px]">
                                <a
                                    v-for="(item, i) in navigations"
                                    :key="i"
                                    href="#"
                                    class="text-[16px] font-bold text-white opacity-60 mb-[12px]"
                                >
                                    {{item}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <footer-contacts />

                </div>
                <footer-social-icons />
            </div>
        </div>
        <div class="w-full bg-black pt-[29px] pb-[32px] mt-[30px] lg:mt-0">
            <div class="container flex flex-row justify-between items-center mt-0 flex-wrap">
                <p class="text-base text-white poppins-font">
                    All rights reserved oasisexperience.cc
                </p>
                <p class="text-base text-white poppins-font font-[600]">
                    <a href="#" class="mr-[16px] decoration-solid underline decoration-[2px]">
                        Privacy
                    policy</a> / <a href="#" class="ml-[16px] decoration-solid underline decoration-[2px]">Cookie
                    notice</a>
                </p>
            </div>
        </div>
    </footer>
</template>

<script setup>

import ContactsForm from "@/components/ContactsForm.vue";
import FooterSocialIcons from "@/components/FooterSocialIcons.vue";
import FooterContacts from "@/components/FooterContacts.vue";
import {useI18n} from "vue-i18n";

const { t } = useI18n()
const navigations = [
    "About",
    "Services",
    "Toures",
    "Blog",
    "News",
    "Contacts",
]

</script>
