<template>
    <header class="header-other_pages">
        <div class="container pt-[155px] pb-[40px]">
            <div class="w-full flex flex-col md:flex-row justify-between md:items-center">
                <h1 class="header-other_pages-title">
                    {{ props.title }}
                </h1>
                <custom-button
                        v-if="reviewsPage"
                        width="245px"
                        :text="t('writeFeedback')"
                        classes="reviews-link_orange magnetic mb-4 md:mb-0"
                        :isButton="true"
                        @click="emits('openModal')"
                />
            </div>
            <span v-for="(matched, idx) in route.matched"
                  :key="idx" class="flex flex-row items-center text-white">
                    <a href="/" class="relative group">
                        Главная
                        <div class="header-other_pages-link duration-900"></div>
                    </a>
                    <img src="../../../public/images/breadcrumbs-round.svg" class="mx-[6px]" alt="">
                    <a :href="matched.path" class="relative group">
                        {{ matched.meta.title }}
                        <div class="header-other_pages-link duration-900"></div>
                    </a>
            </span>
        </div>
    </header>
</template>

<script setup>
import {defineProps, onBeforeUnmount, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import CustomButton from "@/components/CustomButton.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const emits = defineEmits(['openModal'])

const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    reviewsPage: {
        type: Boolean,
        default: false
    }
})
const route = useRoute()

let windowTop = ref(window.top.scrollY);
let scrollPosition = ref(window.scrollY);
let showSticky = ref(true);
let titleColor = ref('../images/header-title-white.svg');
const onScroll = () => {
    const currentScrollPosition = window.scrollY;
    if (currentScrollPosition < scrollPosition.value) {
        showSticky.value = true
    } else if (currentScrollPosition == 0) {
        showSticky.value = true
    } else {
        showSticky.value = false
    }
    scrollPosition.value = window.scrollY
    windowTop.value = window.top.scrollY
    if (windowTop.value > 100) {
        titleColor.value = "../images/header-title.svg"
    } else {
        titleColor.value = "../images/header-title-white.svg"
    }
};
onMounted(() => {
    window.addEventListener("scroll", onScroll);
});
onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
})
</script>
<style scoped>

</style>
  