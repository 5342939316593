<template>
    <div class="consulting-form_wrap">
        <div class="flex flex-col justify-between w-full items-start z-10">
            <h4 class="text-white font-extrabold text-[32px]">{{t('business.consultation')}}</h4>
        </div>
        <form action="#" class="flex flex-col justify-between w-full items-start mt-[30px]">
            <div class="flex flex-col justify-between w-full">
                <input
                    v-model="form.name"
                    :placeholder="t('footer.form.name')"
                    type="text"
                    class="consulting-form_input"
                >
                <input
                    v-model="form.email"
                    :placeholder="t('footer.form.email')"
                    type="email"
                    class="consulting-form_input mt-[20px]"
                >
            </div>
            <custom-button
                type="submit"
                svg-color="#21242C"
                width="100%"
                :text="t('send')"
                classes="consulting-form_submit magnetic"
                :isButton="true"
            />
        </form>
    </div>
</template>

<script setup>

import {reactive} from "vue";
import CustomButton from "@/components/CustomButton.vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const form = reactive({
    name: '',
    email: ''
})
</script>

<style scoped>

</style>