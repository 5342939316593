<template>
    <header class="header-other_pages">
        <div class="container pt-[155px] pb-[40px]">
            <h1 class="header-page_title md:leading-1">
                {{ props.title }}
            </h1>
            <span
                    v-for="(matched, idx) in route.matched"
                    :key="idx"
                    class="flex flex-row items-center text-white"
            >
                    <a href="/" class="relative group">
                        Главная
                        <div class="bheader-other_pages-link duration-900"></div>
                    </a>
                    <img src="../../public/images/breadcrumbs-round.svg" class="mx-[6px]" alt="">
                    <a :href="matched.path" class="relative group">
                        {{ matched.meta.title }}
                        <div class="bheader-other_pages-link duration-900"></div>
                    </a>
            </span>
        </div>
    </header>
</template>

<script setup>
import {defineProps, onBeforeUnmount, onMounted, ref} from "vue";
import {useRoute} from "vue-router";

const props = defineProps({
    title: {
        type: String,
        default: ''
    }
})
const route = useRoute()

let showSticky = ref(true)
let titleColor = ref('../images/header-title-white.svg')
let windowTop = ref('window.top.scrollY')
let scrollPosition = ref('window.scrollY')

onMounted(() => {
    window.addEventListener("scroll", onScroll);
});
onBeforeUnmount(() => {
    window.removeEventListener("scroll", onScroll);
})
const onScroll = () => {
    const currentScrollPosition = window.scrollY;
    if (currentScrollPosition < scrollPosition.value) {
        showSticky.value = true
    } else if (currentScrollPosition == 0) {
        showSticky.value = true
    } else {
        showSticky.value = false
    }
    scrollPosition.value = window.scrollY
    windowTop.value = window.top.scrollY
    if (windowTop.value > 100) {
        titleColor.value = "../images/header-title.svg"
    } else {
        titleColor.value = "../images/header-title-white.svg"
    }
};


</script>
<style scoped>

</style>
  