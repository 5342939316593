<template>
    <select id="countries" v-model="locale" @change="changeHandler(locale)">
        <option
                v-for="lang in availableLocales"
                :key="lang.code"
                :value="lang.code"
        >{{lang.name}}</option>
    </select>
</template>

<script setup>
const emits = defineEmits(['updateKey'])
const availableLocales = [
        {
            code: 'ru',
            name: 'Ру'
        },
        {
            code: 'en',
            name: 'En'
        }
    ]

import { useI18n } from 'vue-i18n'
const { locale } = useI18n()
const changeHandler = (locale) => {
    emits('updateKey')
    localStorage.setItem('language', locale)
}

</script>