<template>
  <div class="w-full" >
      <h1 class="text-center text-4xl font-semibold mb-3">{{t('auth.forgotPassword')}} ?</h1>
      <p class="text-center text-sm font-medium mb-10 text-grey">{{t('auth.enterEmail')}}</p>
      <form
              action="#"
              class="flex flex-col justify-between w-full items-start mb-10"
              @submit.prevent="submitHandler"
      >
          <div class="w-full mb-4 relative">
              <auth-input
                      v-model="form.email"
                      placeholder="Email"
                      :class="{'error-input': errors.email && errors.email.length}"
                      @input="errors.email = []"
              />

              <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                  <tippy
                          v-if="errors.email && errors.email.length"
                          theme="light"
                          placement="top-end"
                  >
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                          <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round"/>
                      </svg>

                      <template #content>
                          <div class="custom-tooltip">
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                   xmlns="http://www.w3.org/2000/svg">
                                  <path
                                          d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                          fill="#FF6939"/>
                              </svg>
                              <span>{{ errors.email[0] }}</span>
                          </div>
                      </template>
                  </tippy>
              </div>
          </div>

          <div v-if="successMessage" class="text-base text-green mb-2">{{successMessage}}</div>

          <div class="flex-center w-full mt-2">
            <custom-button
                type="submit"
                :text="t('auth.submit')"
                classes="mt-0 relative overflow-hidden animated-link footer-popup_btn magnetic bg-bg-orange oaut_btn"
                :isButton="true"
            />
          </div>
      </form>

      <auth-form-footer
          :text="t('auth.alreadyHave')"
          :link-name="t('auth.signIn')"
          link="/login"
      />

  </div>
</template>

<script setup>

import {reactive, ref} from "vue";
import AuthInput from "@/components/AuthInput.vue";
import AuthFormFooter from "@/components/AuthFormFooter.vue";
import {useI18n} from "vue-i18n";
import CustomButton from "@/components/CustomButton.vue";
import AxiosIns from "@/services/apiService";
import {Tippy} from "vue-tippy";


const {t} = useI18n()

const form = reactive({
    email: ''
})

let successMessage = ref('')

const errors = ref({
    email: [],
})
const submitHandler = async () => {
    const response = await AxiosIns.postData("/api/forgot-password", form);

    if(response.status === 200) {
        successMessage.value = response.message
    }
    if (response.data.errors) {
        errors.value = response.data.errors
    }
}

</script>

<style scoped>

</style>