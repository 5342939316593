<template>
    <VScreen/>
    <VResultsBlock/>
    <VToursBlock/>
    <VAboutUsBlock
        :data="about"
    />
    <div>
        <VWhyChooseUsBlock :data="about.why_choose_us" />
    </div>
    <VServicesBlock class="pt-[56px] sm:pt-[100px]" />
    <VQuestionsBlock/>
    <VNewsBlock/>
    <VReviewsBlock/>
    <Popup class="popup-wrap"
           :class="{ hidden: !showModal }">
        <div class="home-popup_content">
            <svg class="home-popup_svg" @click="closeModal"
                 width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="24" cy="24" r="23.25" stroke="#E8E7EA" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M18.293 18.2931C18.4806 18.1056 18.7349 18.0003 19 18.0003C19.2652 18.0003 19.5195 18.1056 19.707 18.2931L24 22.5861L28.293 18.2931C28.3853 18.1976 28.4956 18.1214 28.6176 18.069C28.7396 18.0166 28.8709 17.989 29.0036 17.9878C29.1364 17.9867 29.2681 18.012 29.391 18.0623C29.5139 18.1125 29.6255 18.1868 29.7194 18.2807C29.8133 18.3746 29.8876 18.4862 29.9379 18.6091C29.9881 18.732 30.0134 18.8637 30.0123 18.9965C30.0111 19.1293 29.9835 19.2605 29.9311 19.3825C29.8787 19.5045 29.8025 19.6148 29.707 19.7071L25.414 24.0001L29.707 28.2931C29.8892 28.4817 29.99 28.7343 29.9877 28.9965C29.9854 29.2587 29.8803 29.5095 29.6948 29.6949C29.5094 29.8803 29.2586 29.9855 28.9964 29.9878C28.7342 29.99 28.4816 29.8892 28.293 29.7071L24 25.4141L19.707 29.7071C19.5184 29.8892 19.2658 29.99 19.0036 29.9878C18.7414 29.9855 18.4906 29.8803 18.3052 29.6949C18.1198 29.5095 18.0146 29.2587 18.0124 28.9965C18.0101 28.7343 18.1109 28.4817 18.293 28.2931L22.586 24.0001L18.293 19.7071C18.1056 19.5196 18.0002 19.2652 18.0002 19.0001C18.0002 18.7349 18.1056 18.4806 18.293 18.2931Z"
                      fill="#21242C"/>
            </svg>
            <img src="../../public/images/success_popup_image.png" class="m-auto">
            <p class="home-popup_text">
                Ваша заявка успешно отправлена!
            </p>
        </div>
    </Popup>
</template>


<script setup>
import VScreen from '../components/SectionsPage/Home/VScreen.vue';
import VResultsBlock from '../components/SectionsPage/Home/VResultsBlock.vue'
import VAboutUsBlock from '../components/SectionsPage/Home/VAboutUsBlock.vue'
import VWhyChooseUsBlock from '../components/SectionsPage/Home/VWhyChooseUsBlock.vue'
import VServicesBlock from '../components/SectionsPage/Home/VServicesBlock.vue';
import VQuestionsBlock from '../components/SectionsPage/Home/VQuestionsBlock.vue';
import VNewsBlock from '../components/SectionsPage/Home/VNewsBlock.vue';
import VReviewsBlock from '../components/SectionsPage/Home/VReviewsBlock.vue';
import {onMounted, ref} from "vue";
import AxiosIns from "@/services/apiService";
import VToursBlock from "@/components/SectionsPage/Home/VToursBlock.vue";

let showModal = ref(false);
let about = ref([]);
const closeModal = () => showModal.value = false;

onMounted(async () => {
    document.querySelector('body').classList.add('bg-gray');
    const {data} = await AxiosIns.getData("/api/about-us");
    about.value = data
})

</script>
