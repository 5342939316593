<template>
    <div class="flex flex-col lg:flex-row lg:flex-wrap justify-between items-center">
        <div v-for="(rate, i) in props.rates"
             :key="i"
             class="services-rate_block bg-gray group duration-500"
             :class="{
                 'bg-why-choose-us-background-color': rate.type === 1,
                 'mt-[32px]': i > 0,
                 'w-full': i+1 !== 3 && i+1 !== 4,
                 'services-rate_block-mini ': i+1 === 3 || i+1 === 4
             }"
        >
            <div class="services-rate_date text-bold focus:ring-blue-500 focus:border-blue-500">
                <p>{{rate.duration}} {{t('days')}}</p>
            </div>
            <h5
                class="text-text-gray font-bold text-[28px] sm:text-[32px] text-2xl"
                :class="{'text-white': rate.type === 1}"
            >{{i <= 9 ? 0 : ''}}{{i+1}}</h5>
            <div class="flex flex-col justify-between w-full items-start z-10 mt-[32px]">
                <h5
                    class="text-title-color font-extrabold text-[28px] sm:text-[32px]"
                    :class="{'text-white': rate.type === 1}"
                >{{rate.title}}</h5>
                <p
                    class="text-lg	text-title-color mt-[16px]"
                    :class="{'text-white': rate.type === 1}"
                >
                    {{rate.description}}
                </p>
            </div>
            <div class="flex flex-row justify-between w-full items-center mt-[46px]">
                <h5
                    class="text-title-color font-extrabold text-[28px] sm:text-[32px]"
                    :class="{'text-white': rate.type === 1}"
                >{{rate.price}}$</h5>
                <router-link to="#"
                             class="bg-serviceButtonColor mt-0 sm:mt-[32px]"
                             :class="{
                    'bg-white': rate.type === 1,
                    'duration-800 services-link': i+1 !== 3 && i+1 !== 4,
                    'services-rate_link-mini': i+1 === 3 || i+1 === 4
                }"
                >
                    <p
                        class="m:w-0 sm:w-0 sm:opacity-0"
                        :class="{'duration-600 group-hover:opacity-100 group-hover:w-[100%] group-hover:delay-200': i+1 !== 3 && i+1 !== 4}"
                    >
                       {{ t('more') }}
                    </p>
                    <svg width="18" height="18" :class="{'ml-[11px] sm:ml-0 group-hover:ml-[11px]': i+1 !== 3 && i+1 !== 4}"
                         viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                              fill="white" class="fill-activeServiceButtonColor"/>
                    </svg>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

const { t } = useI18n()
const props = defineProps(['rates'])
</script>

<style scoped>

</style>