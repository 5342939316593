<template>
    <section class="bg-gray rounded-[32px] lg:rounded-[80px] py-[56px] sm:py-[120px]">
        <div class="container">
            <h2 class="home-news_title">{{ t('news') }}</h2>
            <swiper
                ref="mySwiper"
                class="pb-[56px]"
                :slides-per-view="1"
                :space-between="20"
                :modules="modules"
                :speed="600"
                :breakpoints="swiperOptions.breakpoints"
                :pagination="{ clickable: true }"
            >
                <swiper-slide
                    v-for="(item, i) in newsData"
                    :key="i"
                    :class="`slide-${i+1}`"
                >
                    <div class="home-news-slide_block">
                        <div class="home-news-img">
                            <img :src="item.main_image_url" class="w-full h-full rounded-[24px]" alt="">
                        </div>
                        <div class="p-[32px] rounded-b-[24px]">
                            <p class="home-news-block_date">{{item.date}}</p>
                            <p class="home-news-block_title h-20">{{item.title}}</p>
                          <router-link :to="`/journals/${item.id}`" class="news-link group">
                            {{ t('more') }}
                            <svg width="6" class="ml-[11px]" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M4.29289 4.99943L3.93934 5.35298L0.646393 8.64593C0.646376 8.64595 0.646358 8.64597 0.64634 8.64598C0.552638 8.73974 0.5 8.86687 0.5 8.99943C0.5 9.13201 0.552658 9.25917 0.646393 9.35293C0.740147 9.4466 0.867254 9.49922 0.999786 9.49922C1.13234 9.49922 1.25947 9.44658 1.35323 9.35288L4.29289 4.99943ZM4.29289 4.99943L3.93934 4.64588L0.649938 1.35648C0.560432 1.26246 0.510965 1.13727 0.512093 1.00737C0.513232 0.876276 0.565817 0.75087 0.658521 0.658166C0.751225 0.565461 0.876632 0.512877 1.00773 0.511738C1.13763 0.510609 1.26281 0.560076 1.35683 0.649582L5.35318 4.64593C5.3532 4.64595 5.35322 4.64597 5.35323 4.64598C5.44694 4.73974 5.49957 4.86687 5.49957 4.99943C5.49957 5.13199 5.44694 5.25912 5.35323 5.35288C5.35322 5.35289 5.3532 5.35291 5.35318 5.35293L1.35329 9.35282L4.29289 4.99943Z"
                                    class="stroke-blue fill-blue group-hover:fill-white group-hover:stroke-white"/>
                            </svg>
                          </router-link>
                        </div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script setup>
// Import Swiper Vue.js components
import {Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import AxiosIns from "@/services/apiService"
import {ref} from "vue";
import {useI18n} from "vue-i18n";


const { t } = useI18n()
const newsData = ref([]);

(async () => {
    const response = await AxiosIns.getData("/api/journals?show=1");
    newsData.value = response.data;
    newsData.value.map((item) => {
        item.date = item.created_at.split('T')[0]
    })
})();

const modules = [Pagination]

const swiperOptions = {
    breakpoints: {
        991: {
            pagination: true,
            slidesPerView: 3,
            speed: 0
        },
    }
}


</script>
<style scoped>

</style>
