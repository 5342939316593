import axios from "axios";

const apiClient = axios.create({
    baseURL: "https://lc.oasisexperience.cc", // замените на базовый URL вашего API
    // baseURL: "http://127.0.0.1:8000/", // замените на базовый URL вашего API
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

export default {
    async getData(endpoint, config) {
    const lang = localStorage.getItem('language')
        try {
            const response = await apiClient.get(`${endpoint}?lang=${lang}`, config);
            return response.data;
        } catch (error) {
             return error
        }
    },

    async postData(endpoint, data, config) {
        try {
            const response = await apiClient.post(endpoint, data, config);
            return response.data ? response.data : response;
        } catch (error) {
            return error.response
        }
    }

    // здесь можно добавить другие методы, если необходимо
};
