<template>
    <div class="wrapper flex flex-col min-h-full">
    <BaseHeader @update-key="updateKey"/>
    <div class="page flex-auto" :key="key">
        <slot></slot>
    </div>
    <BaseFooter/>
   </div>  
</template>

<script setup>
import BaseFooter from './BaseFooter.vue';
import BaseHeader from './BaseHeader.vue';
import {ref} from "vue";

let key = ref(1)
const updateKey = () => {
    key.value++;
}

</script>

<style  scoped>

</style>