<template>
    <HeaderOtherPages
            :title="t('reviews')"
            :reviews-page="true"
            @openModal="openModal"
    ></HeaderOtherPages>
    <div class="bg-white rounded-[32px] sm:rounded-[80px] inline-block w-full sm:-mt-[100px] relative">
        <div class="container py-[56px] sm:py-[120px]">
            <div class="flex flex-row items-center justify-start flex-wrap md:ml-[16px]">
                <div
                        v-for="(category, i) in categories"
                        :key="i"
                        class="services-type"
                        :class="{ 'active': category.id === currentCategory }"
                        @click="setCurrentCategory(category.id)"
                >
                    <p>{{ category.name }}</p>
                </div>
            </div>
            <div>
                <review-card
                        v-for="(item,i) in reviewsData"
                        :key="i"
                        :reviewPage="true"
                        :item="item"
                />
            </div>
            <div class="w-full h-[64px] mt-8 cursor-pointer flex-center rounded-3xl bg-gray-strong text-xl text-headerColor">
                {{ t('seeMore') }}
            </div>
        </div>
    </div>
    <Popup class="popup-wrap" :class="{ hidden: !showModal }">
        <div class="home-reviews-popup rounded-none md:rounded-[32px] w-full max-w-[730px] py-14 lg:px-12 px-4 lg:min-h-fit min-h-screen h-full md:h-auto md:min-h-fit">
            <svg class="home-reviews-popup_close" @click="closeModal"
                 width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="28" cy="28" r="27.25" stroke="#E8E7EA" stroke-width="1.5"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.3421 21.3418C21.5609 21.1231 21.8575 21.0003 22.1669 21.0003C22.4763 21.0003 22.773 21.1231 22.9917 21.3418L28.0002 26.3503L33.0087 21.3418C33.1164 21.2304 33.2451 21.1415 33.3874 21.0804C33.5298 21.0192 33.6829 20.9871 33.8378 20.9857C33.9927 20.9844 34.1463 21.0139 34.2897 21.0725C34.4331 21.1312 34.5633 21.2178 34.6729 21.3274C34.7824 21.4369 34.869 21.5672 34.9277 21.7106C34.9864 21.8539 35.0159 22.0076 35.0145 22.1625C35.0132 22.3174 34.981 22.4705 34.9199 22.6128C34.8587 22.7551 34.7698 22.8839 34.6584 22.9915L29.6499 28L34.6584 33.0085C34.8709 33.2285 34.9885 33.5232 34.9859 33.8291C34.9832 34.135 34.8605 34.4276 34.6442 34.644C34.4279 34.8603 34.1353 34.983 33.8294 34.9856C33.5235 34.9883 33.2288 34.8707 33.0087 34.6582L28.0002 29.6497L22.9917 34.6582C22.7717 34.8707 22.477 34.9883 22.1711 34.9856C21.8652 34.983 21.5726 34.8603 21.3563 34.644C21.14 34.4276 21.0173 34.135 21.0146 33.8291C21.012 33.5232 21.1296 33.2285 21.3421 33.0085L26.3506 28L21.3421 22.9915C21.1234 22.7727 21.0005 22.476 21.0005 22.1667C21.0005 21.8573 21.1234 21.5606 21.3421 21.3418Z"
                      fill="#21242C"/>
            </svg>

            <h1 class="font-semibold text-5xl mb-8 w-[75%]">{{ t('writeReview') }}</h1>
            <form
                    action="#"
                    @submit.prevent="submitCallBackForm"
                    class="footer-form"
            >
                <div class="footer-input_wrap">
                    <div class="relative">
                        <input
                                v-model="form.name"
                                :placeholder="t('footer.form.name')"
                                type="text"
                                class="footer-input"
                                :class="{'error-input': errors.name && errors.name.length}"
                                @input="errors.name = []"
                        >

                        <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                            <tippy
                                    v-if="errors.name && errors.name.length"
                                    theme="light"
                                    placement="top-end"
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                          stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>

                                <template #content>
                                    <div class="custom-tooltip">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                    fill="#FF6939"/>
                                        </svg>
                                        <span>{{ errors.name[0] }}</span>
                                    </div>
                                </template>
                            </tippy>
                        </div>
                    </div>


                    <div class="footer-input_wrap lg:flex-row">
                        <div class="mt-[16px] w-full relative">
                            <input
                                    v-model="form.email"
                                    :placeholder="t('footer.form.email')"
                                    type="email"
                                    class="footer-input"
                                    :class="{'error-input': errors.email && errors.email.length}"
                                    @input="errors.email = []"
                            >
                            <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                                <tippy v-if="errors.email && errors.email.length"
                                       theme="light"
                                       placement="top-end"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                              stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>

                                    <template #content>
                                        <div class="custom-tooltip">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                        fill="#FF6939"/>
                                            </svg>
                                            <span>{{ errors.email[0] }}</span>
                                        </div>
                                    </template>
                                </tippy>
                            </div>
                        </div>

                        <div class="lg:ml-[16px] mt-[16px] w-full relative">
                            <!--                  <input-->
                            <!--                          v-model="form.phone"-->
                            <!--                          placeholder="Ваш телефон*"-->
                            <!--                          type="tel"-->
                            <!--                          class="footer-input"-->
                            <!--                          @input="errors.phone = []"-->
                            <!--                  >-->
                            <vue-tel-input
                                    v-model="form.phone"
                                    :inputOptions="{placeholder: t('footer.form.phone')}"
                                    class="footer-input"
                                    :class="{'error-input': errors.phone && errors.phone.length}"
                                    @input="errors.phone = []"
                            ></vue-tel-input>

                            <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                                <tippy
                                        v-if="errors.phone && errors.phone.length"
                                        theme="light"
                                        placement="top-end"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                              stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>

                                    <template #content>
                                        <div class="custom-tooltip">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                        fill="#FF6939"/>
                                            </svg>
                                            <span>{{ errors.phone[0] }}</span>
                                        </div>
                                    </template>
                                </tippy>
                            </div>
                        </div>

                    </div>

                    <div class="footer-input_wrap lg:flex-row">
                        <div class="footer-select_wrap mr-[16px] relative">
                            <custom-select
                                    :optionsList="contacts"
                                    :defaultLabel="selectedOfficeLabel"
                                    :class="{'error-input': errors.contact_id && errors.contact_id.length}"
                                    @change="changeContact"
                            />
                            <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                                <tippy
                                        v-if="errors.contact_id && errors.contact_id.length"
                                        theme="light"
                                        placement="top-end"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                              stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>

                                    <template #content>
                                        <div class="custom-tooltip">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                        fill="#FF6939"/>
                                            </svg>
                                            <span>{{ errors.contact_id[0] }}</span>
                                        </div>
                                    </template>
                                </tippy>
                            </div>

                        </div>
                        <div class="footer-select_wrap relative">
                            <custom-select
                                    :optionsList="services"
                                    :defaultLabel="selectTypeQuestionOptionsLabel"
                                    :class="{'error-input': errors.service_id && errors.service_id.length}"
                                    @change="changeService"
                            />
                            <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                                <tippy
                                        v-if="errors.service_id && errors.service_id.length"
                                        theme="light"
                                        placement="top-end"
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                              stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>

                                    <template #content>
                                        <div class="custom-tooltip">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                        fill="#FF6939"/>
                                            </svg>
                                            <span>{{ errors.service_id[0] }}</span>
                                        </div>
                                    </template>
                                </tippy>
                            </div>
                        </div>
                    </div>

                    <div class="relative">
                <textarea
                        v-model="form.body"
                        :placeholder="t('footer.form.message')"
                        type="text"
                        class="footer-textarea"
                        :class="{'error-input': errors.body && errors.body.length}"
                        @input="errors.body = []"
                />

                        <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                            <tippy
                                    v-if="errors.body && errors.body.length"
                                    theme="light"
                                    placement="top-end"
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z"
                                          stroke="#FF6939" stroke-width="1.5" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>

                                <template #content>
                                    <div class="custom-tooltip">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                    fill="#FF6939"/>
                                        </svg>
                                        <span>{{ errors.body[0] }}</span>
                                    </div>
                                </template>
                            </tippy>
                        </div>
                    </div>

                    <div class="mt-[24px] flex-row-start">
                        <label class="checkbox_wrapper">
                            <input
                                v-model="form.services"
                                type="checkbox"
                            >
                            <span class="checkmark"></span>
                        </label>
                        <p class="footer-label">
                            {{ t('footer.form.terms.first') }}
                            <a href="#" class="text-bg-orange text-[14px]">
                                {{ t('footer.form.terms.second') }}
                            </a>
                        </p>
                    </div>
                    <div class="mt-[24px] flex-row-start">
                        <label class="checkbox_wrapper">
                            <input
                                v-model="form.services"
                                type="checkbox"
                            >
                            <span class="checkmark"></span>
                        </label>
                        <p class="footer-label">
                            {{t('footer.form.terms.terth')}}
                        </p>
                    </div>
                </div>
                <custom-button
                        type="submit"
                        width="100%"
                        :text="t('send')"
                        classes="footer-popup_btn magnetic"
                        :isButton="true"
                />
            </form>

        </div>
    </Popup>

</template>

<script setup>
import ReviewCard from "@/components/ReviewCard.vue";
import {onMounted, reactive, ref} from "vue";
import AxiosIns from "@/services/apiService";
import HeaderOtherPages from "@/components/Ui/HeaderOtherPages.vue";
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import CustomSelect from "@/components/Ui/CustomSelect.vue";
import CustomButton from "@/components/CustomButton.vue";
import {Tippy} from "vue-tippy";
import {VueTelInput} from "vue-tel-input";

const {t} = useI18n()
const store = useStore()


let reviewsData = ref([])

let showModal = ref(false);
let currentCategory = ref(1)
const categories = [
    {id: 1, name: 'Все'},
    {id: 2, name: 'Визы'},
    {id: 3, name: 'Китас'},
    {id: 4, name: 'Китап'},
]

const form = reactive({
    name: '',
    email: '',
    phone: '',
    contact_id: '0',
    service_id: '0',
    body: '',
    rating: 5,
    privacy: true,
    services: true,
})

let errors = ref({
    name: [],
    email: [],
    phone: [],
    contact_id: [],
    service_id: [],
    body: [],
    privacy: true,
    services: true,
})


const selectedOfficeLabel = t('footer.form.chooseOffice')
const selectTypeQuestionOptionsLabel = t('footer.form.selectApplication')

const changeContact = (val) => {
    form.contact_id = val.id;
    errors.value.contact_id = []
}
const changeService = (val) => {
    form.service_id = val.id;
    errors.value.service_id = []
}
const submitCallBackForm = async () => {
    const {data} = await AxiosIns.postData("/api/reviews", form);

    if (data.errors) {
        errors.value = data.errors
    } else {
        for (let key in form) {
            if (key === 'contact_id' || key === 'service_id') {
                form[key] = '0'
            } else {
                form[key] = ''
            }
        }
        closeModal()
      const {data} = await AxiosIns.getData("/api/reviews");
      reviewsData.value = data
    }
}

const setCurrentCategory = (id) => {
    currentCategory.value = id
}

const openModal = () => {
    showModal.value = true
    store.commit('setIsNewsModal', true)
}

const closeModal = () => {
    store.commit('setIsNewsModal', '')
    showModal.value = false
};

let contacts = ref([])
let services = ref([])

onMounted(async () => {
    const {data} = await AxiosIns.getData("/api/reviews");
    reviewsData.value = data

    const req = await AxiosIns.getData("/api/user-request-data");
    contacts.value = req.data.contacts
    services.value = req.data.services
})
</script>

<style scoped>
.error-input {
    border: 1px solid #FF5C01;
}

.error-input::placeholder {
    color: #FF5C01;
}
</style>
<style>
.error-input .vti__input::placeholder {
    color: #FF5C01;
}
</style>