<template>
    <div class="home-reviews_slide">
        <div class="flex flex-col sm:flex-row items-start justify-between w-full">
            <p class="text-title-color text-2xl text-left font-[600]">{{item.name}}</p>
            <div class="flex flex-row w-fit mt-[10px] sm:mt-0">
                <img
                    v-for="star in item.rating"
                    :key="star"
                    src="/images/star-yellow.svg"
                    class="mr-[8px]"
                    alt="star"
                >
                <img
                    v-for="emptyStar in (5 - item.rating)"
                    :key="emptyStar"
                    src="/images/star-empty.svg"
                    class="mr-[8px]"
                    alt="star"
                >
                <p
                    v-if="reviewPage"
                    class="px-4 py-2 ml-5 bg-white text-bg-orange border border-greyBorder rounded-[80px] text-base font-semibold"
                >
                    <span>Виза</span>
                </p>
            </div>
        </div>
        <p class="text-reviewsOrange text-[14px] mt-[11px] text-left font-[400]">{{item.user_name}}</p>
        <p v-if="reviewPage" class="text-base text-title-color mt-[25px]">{{item.body}}</p>
        <p v-else class="text-base text-title-color mt-[25px]">{{item.shortBody ? item.shortBody : item.longBody}}
            <span v-if="item.longBody && item.shortBody">...</span>
            <button v-if="item.longBody && item.shortBody" class="appearance-none text-blue"
                    @click="emits('openModal', item)">
                {{ t('more') }}
            </button>
        </p>
        <div v-if="reviewPage" class="mt-6 pt-6 w-full border-t border-gray-light flex">
            <div class="mr-6">
                <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1051_44092)">
                        <rect width="62" height="62" fill="#3772FF"/>
                        <path d="M15.7349 37.0547C11.9579 37.0547 9.3233 34.5493 9.3233 30.803C9.3233 27.2674 11.7947 24.5513 15.5017 24.5513C19.3719 24.5513 21.5868 27.244 21.5868 30.6391C21.5868 31.0137 21.5868 31.3649 21.5169 31.7396H12.3542C12.564 33.2615 13.6365 34.4323 15.7815 34.4323C17.2736 34.4323 18.1829 33.7532 18.6492 32.8635L21.4236 33.683C20.7475 35.2284 18.8357 37.0547 15.7349 37.0547ZM12.3542 29.7962H18.5792C18.4394 28.3445 17.3203 27.1737 15.4784 27.1737C13.6832 27.1737 12.5174 28.4381 12.3542 29.7962ZM34.3929 36.7269H30.8724L28.098 32.9571L25.3235 36.7269H21.8263L26.2328 30.7328L22.0128 24.8791H25.51L28.098 28.4615L30.7092 24.8791H34.1831L29.9865 30.7328L34.3929 36.7269ZM42.2097 24.5513C45.4737 24.5513 48.015 27.1971 48.015 30.803C48.015 34.3854 45.4737 37.0547 42.2097 37.0547C40.531 37.0547 39.2487 36.3523 38.3861 35.2752V41.644H35.3085V24.8791H38.3861V26.3074C39.2487 25.2303 40.531 24.5513 42.2097 24.5513ZM41.6734 34.2918C43.6086 34.2918 44.9841 32.8401 44.9841 30.7796C44.9841 28.7425 43.6086 27.3142 41.6734 27.3142C39.8083 27.3142 38.3161 28.7425 38.3161 30.7796C38.3161 32.8401 39.8083 34.2918 41.6734 34.2918Z" fill="white"/>
                        <path d="M53.2687 35.6396C53.2687 37.1237 52.2472 38.2367 50.7678 38.2367C49.2708 38.2367 48.2669 37.1237 48.2669 35.6396C48.2669 34.1555 49.2708 33.0425 50.7678 33.0425C52.2472 33.0425 53.2687 34.1555 53.2687 35.6396Z" fill="#FF6939"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_1051_44092">
                            <rect width="62" height="62" rx="16" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            </div>
            <div>
                <p class="mb-2 text-xl font-semibold">Оasisexperience.cc</p>
                <p class="text-base">Спасибо за ваш отзыв!</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useI18n} from "vue-i18n";

defineProps(['item', 'reviewPage'])
const emits = defineEmits(['openModal'])

const {t} = useI18n()
</script>

<style scoped>

</style>