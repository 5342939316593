<template>
    <div>
        <!-- Контейнер для FilePond -->
<!--        <div ref="pond"></div>-->
        <file-pond
            name="test"
            ref="pond"
            label-idle="Drop files here..."
            :allow-multiple="true"
            :accepted-file-types="acceptedFileTypes"
            server="/api"
            v-bind="options"
            :files="myFiles"
            @error="handleFilePondError"
        />
    </div>
</template>

<script setup>

import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview"
import {ref} from "vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n()

const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview
);

let myFiles = ref([])

defineProps({
    // Передаем свойства в компонент FilePond
    acceptedFileTypes: {
        type: Array,
        default: () => ['image/png', 'image/jpeg', 'image/gif'],
    },
    maxFileSize: {
        type: String,
        default: '10MB',
    }
})

const emits = defineEmits(['file-loaded'])
const handleFilePondError = () => {
    emits('file-loaded')
}

let options = {
    allowMultiple: true,
    allowImagePreview: false,
    labelIdle:
        '<p>'+ t('dragDrop') + '<span class="filepond--label-action">' + t('overview') + ' </span></p>',
    labelFileWaitingForSize: 'Ожилаю размер файла',
    labelFileLoading: 'Загружаю',
    labelFileProcessing: 'Загружаю',
    labelFileProcessingComplete: 'Файл загружен',
    labelFileProcessingAborted: 'Загрузка отменена',
    labelFileProcessingRevertError: 'Что-то пошло не так',
    labelFileProcessingError: 'Что-то пошло не так',
    labelFileRemoveError: 'Файл не удален',
    labelTapToCancel: 'Отменить загрузку',
    labelTapToRetry: 'Нажмите для повтора',
    labelTapToUndo: 'Удалить файл',
    labelButtonRemoveItem: 'Удалить',
    labelButtonAbortItemLoad: 'Прервать загрузку',
    labelButtonRetryItemLoad: 'Повторить загрузку',
    labelButtonAbortItemProcessing: 'Прервать',
    labelButtonUndoItemProcessing:'Отменить',
    labelButtonRetryItemProcessing: 'Повторить',
    labelButtonProcessItem: 'Загрузить',
    server: {
        url: '/upload',
        method: 'POST',
    }
}

</script>
<style>
.filepond--credits{
    display: none!important;
}

.filepond--panel-root {
    border-radius: 16px!important;
    background-color: transparent;
}
.filepond--panel{
    border: solid 1px #95999f!important;
}
.filepond--root{
    border: solid 1px #E8E7EA!important;
    border-radius: 16px;
    margin-bottom: 0px;
}
</style>
