<template>
    <section class="bg-gray w-full">
        <div class="home-services_content">
            <h2 class="home-services_title max-w-[60%]">
              {{ t('ourPopularServicesAndOfferings') }}
            </h2>
            <div class="flex flex-row items-center md:mt-[78px] justify-between flex-wrap">
                <div
                    v-for="(service, i) in firstServices"
                    :key="i"
                    class="group duration-500"
                    :class="{
                        'home-services_block-white': service.card_color !== 2,
                        'home-services_block-blue': service.card_color === 2,
                        'bg-service_pattern_type-2 bg-right-bottom bg-no-repeat': service.card_color === 3
                    }"
                >
                    <div class="flex flex-col justify-between w-full items-start z-10">
                        <h4
                            :class="{
                                'home-services_white-title': service.card_color !== 2,
                                'text-white font-extrabold text-[28px] sm:text-[32px]': service.card_color === 2,
                            }"
                        >{{ service.title }}</h4>
                        <p
                            class=""
                            :class="{
                                'home-services_white-text': service.card_color !== 2,
                                'text-base sm:text-lg text-white mt-4 mb-[10px]': service.card_color === 2,
                            }"
                        >
                           {{service.short_description}}
                        </p>
                        <div class="flex flex-row justify-start items-center flex-wrap">
                            <div
                                    v-for="(item, idx) in service.extensions"
                                    :key="idx"
                            >
                                <tippy theme="light">
                                    <div
                                        class="info"
                                        :class="{
                                            'home-services_white-info': service.card_color !== 2,
                                            'home-services_blue-info': service.card_color === 2,
                                        }"
                                    >
                                        <p class="mr-[8px] info-hover:text-reviewsOrange">{{ item.text }}</p>
                                        <svg v-if="service.card_color === 2" v-tippy width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                fill="white"/>
                                        </svg>
                                        <svg v-else v-tippy width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                fill="#FF6939"/>
                                        </svg>
                                    </div>



                                    <template #content>
                                        <div class="custom-tooltip">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                    fill="#FF6939"/>
                                            </svg>
                                            <span>{{item.info}}</span>
                                        </div>
                                    </template>
                                </tippy>
                            </div>
                        </div>
                    </div>
                    <router-link :to="`/services/${service.id}`"
                                 class="duration-800"
                                 :class="{
                                    'home-services_white-link': service.card_color !== 2,
                                    'home-services_blue-link': service.card_color === 2,
                                 }"
                    >
                        <p class="home-services_link_text duration-600">
                           {{ t('more') }}</p>
                        <svg width="18" height="18" viewBox="0 0 18 18" class=" ml-[11px] sm:ml-0 group-hover:ml-[11px]"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                                    fill="#21242C" class="fill-activeServiceButtonColor"/>
                        </svg>
                    </router-link>
                </div>
            </div>
            <div class="flex flex-row justify-between flex-wrap">
                <div class="flex flex-row flex-wrap justify-between xl:w-[100%]">
                    <div
                            v-for="(item, i) in services"
                            :key="i"
                            class="card-service group"
                            :class="{
                                'bg-service_pattern_type-2 bg-right-bottom bg-no-repeat': item.card_color === 3,
                                'bg-blue': item.card_color === 2
                            }"
                    >
                        <div class="flex flex-col justify-between w-full items-start z-10">
                            <h4
                                class="home-services_white-title"
                                :class="{
                                    'text-white': item.card_color === 2
                                }"
                            >{{ item.title }}</h4>
                            <p
                                class="home-services_white-text"
                                :class="{
                                    'text-white': item.card_color === 2
                                }"
                            >{{ item.short_description }}</p>
                            <div class="flex flex-row justify-start items-center flex-wrap">
                                <div
                                    v-for="(info,idx) in item.extensions"
                                    :key="idx"
                               >
                                    <tippy theme="light">
                                       <div
                                           class="info"
                                           :class="item.card_color === 2 ? 'home-services_blue-info' : 'home-services_white-info'"
                                       >
                                           <p class="mr-[8px] info-hover:text-reviewsOrange">{{ info.text }}</p>
                                           <svg v-if="item.card_color === 2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                               <path
                                                   d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                   fill="white"/>
                                           </svg>
                                           <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                               <path
                                                   d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                   fill="#FF6939"/>
                                           </svg>
                                       </div>

                                        <template #content>
                                            <div class="custom-tooltip">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                        fill="#FF6939"/>
                                                </svg>
                                                <span>{{info.info}}</span>
                                            </div>
                                        </template>
                                    </tippy>
                                </div>
                            </div>
                        </div>
                        <router-link :to="`/services/${item.id}`"
                                     class="duration-800"
                                     :class="{
                                        'home-services_white-link': item.card_color !== 2,
                                        'home-services_blue-link': item.card_color === 2,
                                    }"
                        >
                            <p class="home-services_link_text duration-600">
                               {{ t('more') }}</p>
                            <svg width="18" height="18" class="ml-[11px] sm:ml-0 group-hover:ml-[11px]"
                                 viewBox="0 0 18 18"
                                 fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                                        fill="white" class="fill-activeServiceButtonColor"/>
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="flex flex-row justify-between flex-wrap">
                <div
                    class="group duration-500 home-services"
                    :class="{
                        'bg-blue': lastService.card_color === 2,
                        'bg-service_pattern_type-2 bg-right-bottom bg-no-repeat': lastService.card_color === 3
                    }"
                >
                    <div class="flex flex-col justify-between w-full items-start z-10">
                        <h4
                            class="home-services_white-title"
                            :class="{
                                'text-white': lastService.card_color === 2
                            }"
                        >
                            {{lastService.title}}
                        </h4>
                        <p
                            class="home-services_white-text"
                            :class="{
                                'text-white': lastService.card_color === 2
                            }"
                        >
                            {{lastService.short_description}}
                        </p>
                        <div class="flex flex-row justify-start items-center flex-wrap">
                            <div v-for="(item,i) in lastService.extensions"
                                 :key="i"
                            >
                                <tippy theme="light">
                                   <div
                                       class="info"
                                       :class="lastService.card_color === 2 ? 'home-services_blue-info' : 'home-services_white-info'"
                                   >
                                       <p class="mr-[8px] info-hover:text-reviewsOrange">{{ item.text }}</p>
                                       <svg v-if="lastService.card_color === 2" width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                           <path
                                               d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                               fill="white"/>
                                       </svg>
                                       <svg v-else width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                           <path
                                               d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                               fill="#FF6939"/>
                                       </svg>
                                   </div>

                                    <template #content>
                                        <div class="custom-tooltip">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                    fill="#FF6939"/>
                                            </svg>
                                            <span>{{item.info}}</span>
                                        </div>
                                    </template>
                                </tippy>
                            </div>
                        </div>
                    </div>
                    <router-link
                        :to="`/services/${lastService.id}`"
                        class="duration-800 mt-[50px]"
                        :class="{
                            'home-services_white-link': lastService.card_color !== 2,
                            'home-services_blue-link': lastService.card_color === 2,
                        }"
                    >
                        <p class="home-services_link_text duration-600">
                           {{ t('more') }}</p>
                        <svg width="18" height="18" class="ml-[11px] sm:ml-0 group-hover:ml-[11px]" viewBox="0 0 18 18"
                             fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                    d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                                    fill="white" class="fill-activeServiceButtonColor"/>
                        </svg>
                    </router-link>
                </div>
                <div
                        class="home-services_all-wrap">
                    <div class="flex flex-row justify-center w-full items-center z-10">
                        <h4 class="text-white font-medium text-[28px] sm:text-[32px]">{{ t('allServices') }}</h4>
                        <router-link to="/services/"
                                     class="home-services_all">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                        d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                                        fill="#21242C" class="fill-activeServiceButtonColor"/>
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import {onMounted, ref} from "vue";
import AxiosIns from "@/services/apiService";
import {Tippy} from "vue-tippy";
import {useI18n} from "vue-i18n";


const { t } = useI18n()

let services = ref([])
let lastService = ref({})
let firstServices = ref([])

onMounted(async () => {
    const {data} = await AxiosIns.getData("/api/services?show=1");
    services.value = data
    lastService.value = services.value.splice(services.value.length -1)[0]
    firstServices.value = services.value.splice(0,2)
})

</script>
<style scoped>

</style>
