<template>
    <div
            class="custom-select"
            :class="{'flag-select': optionsList[0]?.flag}"
            @click="toggleOptions"
    >
        <div :class="['selected-option', { show: showOptions }]">
            <img v-if="selectedImage" class="w-[15px] mr-3 rounded" :src="selectedImage" alt="flag">
            <span :class="{'flag-mobile_option': flag.icon}">{{ selected }}</span>
            <span class="icon" :class="{'flag-icon': flag.icon}" >
        <svg class="icon-chevron" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
          <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
        </svg>
      </span>
        </div>
        <div class="options" :class="{ show: showOptions }">
            <div
                    v-for="(option, index) in optionsList"
                    :key="index"
                    class="option"
                    :class="{'flex items-center': option.flag}"
                    @click="selectOption(option)"
            >
                <div v-if="option.flag">
                    <img class="w-[15px] mr-3 rounded" :src="option.flag" alt="flag">
                </div>
                <span :class="{'flag-mobile_option': flag.icon}">{{ option.name }}</span>
            </div>
        </div>
    </div>
</template>


<script setup>
import {defineProps, defineEmits, ref} from "vue";

const emit = defineEmits(['close'])

const props = defineProps({
    optionsList: {
        type: Array,
        default: () => [],
    },
    flag: {
        type: Object,
        default: () => ({}),
    },
    defaultLabel: {
        type: String,
        default: 'Выберите опцию',
    },
    value: {
        type: Boolean,
        default: false,
    },
})
let selected = ref(props.defaultLabel)
let showOptions = ref(props.value)
let selectedImage = ref(props.flag.icon)

const toggleOptions = () => {
    showOptions.value = !showOptions.value;
}

const selectOption = (option) => {
    if(option.flag) {
        selectedImage.value = option.flag
    }
    selected.value = option.name;
    emit('change', option);
}
</script>

<style>
/* добавьте эти стили к имеющимся */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.options {
    /* ... */
    display: none;
    /* ... */
}
.options.show {
    display: block;
}
.error-input .icon-chevron{
    display: none;
}
.error-input .selected-option{
    color: #FF5C01;
}
.custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
    border-radius: 16px;
    height: fit-content;
}

.selected-option {
    background-color: white;
    border: 1px solid #E8E7EA;
    border-radius: 16px;
    color: #8b8b8b;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    padding: 13px 16px;
    user-select: none;
}

.icon {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
}

.icon-chevron {
    transition: transform 0.3s;
}

.show .icon-chevron {
    transform: rotate(180deg);
}

.options {
    background-color: white;
    border: 1px solid #E8E7EA;
    border-radius: 16px;
    margin-top: 2px;
    position: absolute;
    width: 100%;
    z-index: 1;
}

.option {
    color: #8b8b8b;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 16px 24px;
    user-select:none;
    border-bottom: solid 1px #E8E7EA;
}

.options {
    /* ... */
    border-radius: 16px;
    overflow: hidden;
}

.option:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.option:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-bottom: none;
}

.option:hover {
    background-color: #f5f5f5;
}

.selected-option {
    background-color: white;
    border: 1px solid #E8E7EA;
    border-radius: 16px;
    color: #8b8b8b;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    padding: 13px 16px;
    user-select: none;
}

.icon {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
}

.icon-chevron {
    transition: transform 0.3s;
}

.show .icon-chevron {
    transform: rotate(180deg);
}

.options {
    background-color: white;
    border: 1px solid #E8E7EA;
    border-radius: 16px;
    margin-top: 2px;
    position: absolute;
    width: 100%;
    z-index: 1;
    display: none;
}

.options.show {
    display: block;
}

.custom-select {
    position: relative;
    display: inline-block;
    width: 100%;
}

.selected-option.show + .options {
    display: block;
}

.flag-select {
  width: 127px;
  padding: 0;
  border: solid #E8E7EA 1px;
}
.flag-select .selected-option,  .flag-select .option{
    border: none;
    font-size: 13px;
    padding: 8px 10px;
}

@media(max-width: 480px) {
    .flag-mobile_option {
        display: none;
    }
    .flag-select {
        width: 60px;
    }
    .flag-icon {
        margin-left: -4px;
    }
}
</style>
