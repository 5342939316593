export const en = {
    menu: 'Menu',
    news: 'News',
    aboutUs: 'About Us',
    contacts: 'Contacts',
    whyChooseUs: 'Why choose us?',
    faq: 'Questions and Answers',
    services: 'Our services and offerings',
    journal: 'Press center',
    tours: 'Tours',
    bussinesConsaltings: 'Bussines Consaltings',
    reviews: 'Reviews about us',
    ourPopularServicesAndOfferings: 'Our popular services and offerings.',
    allServices: 'All Services',
    more: 'More details',
    writeToUs: 'Write to us',
    send: 'Send',
    increase: 'Increase',
    allReviews: 'All reviews',
    overview: 'Overview',
    dragDrop: 'Drag and drop a file here to upload or select on your computer',
    authorization: 'Authorization',
    registration: 'Registration',
    myProfile: 'My profile',
    travelers: 'Travelers',
    myVisas: 'My visas',
    myTours: 'My tours',
    logout: 'Logout',
    otherServices: 'Other services',
    backToHome: 'Back to home',
    answer: 'Answer',
    writeFeedback: 'Write a feedback',
    seeMore: 'See more',
    days: 'days',
    writeReview: 'Write your review or suggestion',

    header: {
        home: 'Home',
        businessInBali: 'Business and Consaltings',
        aboutUs: 'About Us',
        transportRental: 'Transport rental',
        questionAnswers: 'Questions & Answers',
        services: 'Services',
        contacts: 'Contacts',
        pressCenter: 'Press Center',
        tours: 'Tours',
        buildRoute: 'Build a route',
        addressCanggu: '129090 Moscow, Prospekt Mira metro station, Shchepkina street, 29',
        addressUluwatu: '129090 Moscow, Prospekt Mira metro station, Shchepkina street, 29',
        addressJimbaran: '129090 Moscow, Prospekt Mira metro station, Shchepkina street, 29',
    },
    footer: {
        weWillContactYou: 'We will contact you.',
        anyQuestions: 'Do you have any remaining questions?',
        descAnyQuestions: 'If you have any questions, please use the contact form below. We are always ready to assist you and provide answers to your inquiries.',
        navigator: 'Build a route',
        form: {
            title: 'Contact form.',
            name: 'Name*',
            email: 'Email',
            phone: 'Your phone number*',
            chooseOffice: 'Choose an office',
            selectApplication: 'Select application type',
            upload: 'Upload',
            uploadFile: 'Upload file',
            message: 'Your Message',
            terms: {
                first: '*I have read and understood that my personal data will be processed for the ongoing recruitment process, as described in the',
                second: 'Privacy notice for applicants.',
                terth: 'I agree to receive from OasisExperience.cc information related to the services it offers.'
            },
            successfullySent: 'Your application has been successfully sent!',
        }
    },
    home: {
        heroSubTitle: 'We will assist with the process.',
        heroVisa: 'Tours',
        heroKitas: 'Consulting',
        heroBussines: 'Visa',
        heroInIndonesia: ' in Indonesia',
        heroDescriptions: 'Discover Bali with the help of local experts. Consult and plan your Bali journey with our assistance - from visa to your destination, we take care of you throughout your entire stay.',
    },
    about: {
        team: 'Our Team',
        ourServices: 'Our Sevices',
    },
    business: {
        companyType: 'Types of companies',
        investmentType: 'Investment directions',
        otherDirections: 'Other Directions',
        otherDirectionsDeck: 'If you didn\'t find your specific direction above, please leave a request for an individual consultation.',
        services: 'Business services.',
        businessArticles: 'Articles about business',
        consultation: 'Sign up for a consultation',

    },
    homeResult:{
        eliteServices: 'Elite Services',
        customerSatisfaction: 'Customer Satisfaction',
        clients: 'Clients',
        aroundTheClock: 'around the clock',
    },
    service: {
        generalInformation: 'General information',
        important: 'Important to know',
        documents: 'Documents',
        stages: 'Stages',
        stagesObtaining: 'Stages of obtaining',
        rates: 'Rates',
        whatPurpose: 'What is the purpose of the visa?',
        submitApplication: 'Submit your application'
    },
    auth: {
        signIn: 'Sign In',
        signUp: 'Sign Up',
        signInApple: 'Sign in with Apple',
        signInGoogle: 'Sign in with Google',
        signUpApple: 'Sign up with Apple',
        signUpGoogle: 'Sign up with Google',
        socialCampaigns: 'Your Social Campaigns',
        orEmail: 'Or with email',
        forgotPassword: 'Forgot Password',
        password: 'Password',
        notMember: 'Not a Member yet?',
        submit: 'Submit',
        alreadyHave: 'Already have an Account?',
        enterEmail: 'Enter your email to reset your password.',
        registerUsing: 'Register using Google or AppleId account.',
        name: 'Name',
        surename: 'Surname',
        repeatPassword: 'Repeat Password',
        passwordRequirement: 'Use 8 or more characters with a mix of letters, numbers & one capital letter.',
        accept: 'I Accept the',
        terms: 'Terms',
        setupNewPassword: 'Setup New Password',
        alreadyReset: 'Have you already reset the password ?'
    },
}