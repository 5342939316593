<template>
    <HeaderNews :title='data.title'></HeaderNews>
    <div class="services-visa_wrap">
        <div class="container flex flex-row justify-between items-start">
            <div class="w-[30%] static xl:sticky top-[120px] left-0 hidden lg:block">
                <h5
                        v-for="item in sections"
                        :key="item.key"
                        class="consulting-sidebar cursor-pointer duration-500"
                        :class="{sidebar_active: item.key == currentSection}">
                    <img
                            src="../../../public/images/sidebar-border.svg"
                            class="hidden mr-[12px]"
                            alt=""
                    >
                    {{ item.text }}
                </h5>
<!--                <a href="#"-->
<!--                   class="news-btn">-->
<!--                    Написать нам-->
<!--                    <svg width="18" height="18" viewBox="0 0 18 18" class="ml-[11px]" fill="none"-->
<!--                         xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"-->
<!--                              fill="#21242C"/>-->
<!--                    </svg>-->
<!--                </a>-->
            </div>
            <div class="w-full lg:w-[70%] lg:pl-8">
               <div v-for="(item,i) in content" :key="i">
                   <div v-if="item.layout === 'block_text'" id="1">
                       <h3 :id="i" class="text-title-color text-5xl font-extrabold mb-[24px] section_title leading-[1.2]">
                          {{item.attributes.title}}
                       </h3>
                       <div class="text-lg py-[24px] journal-text" v-html="item.attributes.content"></div>
                   </div>

                   <div v-if="item.layout === 'block_image'" id="2">
                       <img
                           v-for="(image, i) in item.attributes"
                           :key="i"
                           :src="image.original_url"
                           class="w-full h-auto max-h-[400px] rounded-[24px] mt-[58px] object-cover"
                           alt=""
                       >
                   </div>

                   <div id="3" v-if="item.layout === 'block_gallery'" class="flex flex-row justify-between items-start mb-20 mt-[26px] flex-wrap">
                       <div
                           v-for="(image, i) in Object.values(item.attributes)"
                           :key="i"
                           class="sm:w-[calc(100%/2-16px)] mt-[32px] relative rounded-[24px] cursor-pointer"
                           @mouseover="mouseOverHandler('about-img_magnetic', 'increase', i === lastElement(item))"
                           @mouseout="mouseOutHandler(i === lastElement(item))"
                           @click="showMultiple(i === lastElement(item), Object.values(item.attributes))"
                       >

                           <img :src="image.original_url"
                                class="w-full h-[240px] rounded-[24px] object-cover" alt="">
                       </div>
                   </div>

                   <div id="4" v-if="item.layout === 'block_video'" class="rounded-[24px] mt-[58px] mb-20">
                       <div v-if="showVideo !== item.attributes.video" class="w-full h-auto sm:h-[400px] rounded-[24px] relative">
                           <img
                               v-if="item.attributes.thumbnail"
                               :src="item.attributes.thumbnail"
                               class="w-full h-full rounded-[24px]"
                           />
                           <img
                               v-else
                               :src="`//img.youtube.com/vi/${item.attributes.video}/maxresdefault.jpg`"
                               class="w-full h-full rounded-[24px] object-cover"
                           />
                           <img
                               src="@/assets/images/play.png"
                               class="absolute-center w-[100px] cursor-pointer"
                               @click="playVideo(item.attributes.video)"
                           />
                       </div>
                       <iframe
                           v-if="showVideo === item.attributes.video"
                           class="w-full h-[400px] rounded-[24px]"
                           :src="`https://www.youtube.com/embed/${item.attributes.video}?autoplay=1`"
                           title="YouTube video player" frameborder="0"
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                           allowfullscreen>
                       </iframe>
                   </div>
               </div>

                <services-deadline id="4" :deadline-data="extensions" />
            </div>
        </div>
        <div class="container pt-[120px]">
            <h3 class="text-title-color text-5xl font-extrabold mb-[24px]">
                Другие новости
            </h3>
            <div v-if="latestNews.length" class="flex flex-row justify-between items-start flex-wrap">
                <div
                    v-for="(item,i) in latestNews"
                    :key="i"
                    class="news-block"
                >
                    <div class="bg-cube-color md:h-[280px] rounded-[24px] w-full">
                        <img :src="item.main_image_url" class="w-full h-full rounded-[24px]" alt="">
                    </div>
                    <div class="p-[32px]">
                        <p class="news-date">{{ dateFormat(item.created_at)}}</p>
                        <p class="home-news-block_title">{{item.title}}</p>
                        <router-link :to="`/journals/${item.id}`"
                                     class="news-link group">
                           {{ t('more') }}
                            <svg width="6" class="ml-[11px]" height="10" viewBox="0 0 6 10" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.29289 4.99943L3.93934 5.35298L0.646393 8.64593C0.646376 8.64595 0.646358 8.64597 0.64634 8.64598C0.552638 8.73974 0.5 8.86687 0.5 8.99943C0.5 9.13201 0.552658 9.25917 0.646393 9.35293C0.740147 9.4466 0.867254 9.49922 0.999786 9.49922C1.13234 9.49922 1.25947 9.44658 1.35323 9.35288L4.29289 4.99943ZM4.29289 4.99943L3.93934 4.64588L0.649938 1.35648C0.560432 1.26246 0.510965 1.13727 0.512093 1.00737C0.513232 0.876276 0.565817 0.75087 0.658521 0.658166C0.751225 0.565461 0.876632 0.512877 1.00773 0.511738C1.13763 0.510609 1.26281 0.560076 1.35683 0.649582L5.35318 4.64593C5.3532 4.64595 5.35322 4.64597 5.35323 4.64598C5.44694 4.73974 5.49957 4.86687 5.49957 4.99943C5.49957 5.13199 5.44694 5.25912 5.35323 5.35288C5.35322 5.35289 5.3532 5.35291 5.35318 5.35293L1.35329 9.35282L4.29289 4.99943Z"
                                      fill="#3772FF" stroke="#3772FF"
                                      class=" group-hover:fill-white group-hover:stroke-white"/>
                            </svg>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <vue-easy-lightbox
            escDisabled
            moveDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
    ></vue-easy-lightbox>
</template>

<script setup>
import HeaderNews from '../../components/HeaderNews.vue';
import ServicesDeadline from "@/components/services/ServicesDeadline.vue";
import VueEasyLightbox from 'vue-easy-lightbox'
import { useStore } from 'vuex'
import AxiosIns from "@/services/apiService";
import {useRoute, useRouter} from "vue-router";
import {useI18n} from "vue-i18n";

document.querySelector('body').classList.add('bg-white');
import {onMounted, ref, watch} from "vue";

const { t } = useI18n()

let sections = []

let index = ref(0)
let currentSection = ref(0)
let visible = ref(false)
let imgs = ref([])
let extensions = ref([])



const store = useStore()
const router = useRouter();
const route = useRoute();

const show = () => {
    store.commit('setIsNewsModal', true)
    visible.value = true
}
const handleHide = () => {
    store.commit('setIsNewsModal', '')
    visible.value = false
}

const showMultiple = (showCondition, images) => {
    if(showCondition) {
        imgs.value = []
        images.map((item, i) => {
            let img = {
                title: `Image ${i+1}`,
                src: item.original_url
            }
            imgs.value.push(img)
        })

        index.value = 0 // index of imgList
        show()
    }

}
const lastElement = (item) => {
    return Object.values(item.attributes).length - 1
}
const mouseOverHandler = (name, text, showCondition) => {
    if(showCondition) {
        store.commit('setCursorInner', t(text))
        store.commit('setCursorClass', name)
    }
};
const mouseOutHandler = (showCondition) => {
    if(showCondition) {
        store.commit('setCursorInner', "")
        store.commit('setCursorClass', "")
    }
};
let data = ref([])
let content = ref([])
let latestNews = ref([])

const dateFormat = (date) => {
    return date.split('T')[0]
}

const getData = async () => {
    const response = await AxiosIns.getData(`/api/journals/${route.params.id}`);
    data.value = response.data;
    extensions.value = response.data.extensions
    content.value = response.data.flexible_content
    sections = response.data.sectionNames
}

const playVideo = (video) => {
    showVideo.value = video
}

let showVideo = ref('')

const getAllData = async () => {
    const response = await AxiosIns.getData("/api/journals");
    const data = response.data

    data.sort((a,b) => {
        return new Date(b.created_at) - new Date(a.created_at);
    });

    latestNews.value = data.splice(0, 3)
}

watch(
    () => route.params.id,
    async () => {
        await loadPage()
    }
)

onMounted(async () => {
   await loadPage()
})

const loadPage = async () => {
    await router.isReady();
    await getData();
    await getAllData();

    window.scrollTo(0, 0)

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
                currentSection.value = parseInt(entry.target.getAttribute('id'))
            }
        })
    })
    document.querySelectorAll('.section_title').forEach((section) => {
        observer.observe(section)
    })
}
</script>