<template>
    <form
        action="#"
        class="flex flex-col justify-between w-full items-start mt-[30px]"
        @submit.prevent="submitHandler"
    >
        <div class="flex flex-col justify-between w-full">
            <div class="relative">
                <input
                        v-model="contactsForm.name"
                        :placeholder="t('footer.form.name')"
                        type="text"
                        class="contacts-input"
                        :class="{'error-input': errors.name && errors.name.length}"
                        @input="errors.name = []"
                >

                <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                    <tippy
                            v-if="errors.name && errors.name.length"
                            theme="light"
                            placement="top-end"
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z" stroke="#FF6939" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <template #content>
                            <div class="custom-tooltip">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                            fill="#FF6939"/>
                                </svg>
                                <span>{{errors.name[0]}}</span>
                            </div>
                        </template>
                    </tippy>
                </div>
            </div>

            <div class="flex flex-col lg:flex-row justify-between w-full">
               <div class="w-full mt-[20px] relative">
                   <input
                       v-model="contactsForm.email"
                       :placeholder="t('footer.form.email')"
                       type="email"
                       class="contacts-input"
                       :class="{'error-input': errors.email && errors.email.length}"
                       @input="errors.email = []"
                   >
                   <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                       <tippy v-if="errors.email && errors.email.length"
                              theme="light"
                              placement="top-end"
                       >
                           <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                               <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z" stroke="#FF6939" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                           </svg>

                           <template #content>
                               <div class="custom-tooltip">
                                   <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                       <path
                                               d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                               fill="#FF6939"/>
                                   </svg>
                                   <span>{{errors.email[0]}}</span>
                               </div>
                           </template>
                       </tippy>
                   </div>
               </div>

                <div class="w-full lg:ml-[24px] mt-[20px] relative">
                    <vue-tel-input
                        v-model="contactsForm.phone"
                        :inputOptions="{placeholder: t('footer.form.phone')}"
                        class="contacts-input px-0"
                        :class="{'error-input': errors.phone && errors.phone.length}"
                        @input="errors.phone = []"
                    ></vue-tel-input>

                    <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                        <tippy
                                v-if="errors.phone && errors.phone.length"
                                theme="light"
                                placement="top-end"
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z" stroke="#FF6939" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <template #content>
                                <div class="custom-tooltip">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                                d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                                fill="#FF6939"/>
                                    </svg>
                                    <span>{{errors.phone[0]}}</span>
                                </div>
                            </template>
                        </tippy>
                    </div>
                </div>


            </div>
            <div class="footer-input_wrap lg:flex-row mt-[10px]">
                <div class="footer-select_wrap mr-[16px] relative">
                    <custom-select
                        :optionsList="contacts"
                        :defaultLabel="selectedOfficeLabel"
                        :class="{'error-input': errors.contact_id && errors.contact_id.length}"
                        @change="changeContact"
                    />
                    <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                        <tippy
                            v-if="errors.contact_id && errors.contact_id.length"
                            theme="light"
                            placement="top-end"
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z" stroke="#FF6939" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <template #content>
                                <div class="custom-tooltip">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                            fill="#FF6939"/>
                                    </svg>
                                    <span>{{errors.contact_id[0]}}</span>
                                </div>
                            </template>
                        </tippy>
                    </div>

                </div>
                <div class="footer-select_wrap relative">
                    <custom-select
                        :optionsList="services"
                        :defaultLabel="selectTypeQuestionOptionsLabel"
                        :class="{'error-input': errors.service_id && errors.service_id.length}"
                        @change="changeService"
                    />
                    <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                        <tippy
                            v-if="errors.service_id && errors.service_id.length"
                            theme="light"
                            placement="top-end"
                        >
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z" stroke="#FF6939" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <template #content>
                                <div class="custom-tooltip">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                            fill="#FF6939"/>
                                    </svg>
                                    <span>{{errors.service_id[0]}}</span>
                                </div>
                            </template>
                        </tippy>
                    </div>
                </div>
            </div>
            <div class="flex flex-col lg:flex-row justify-between w-full mt-[24px]">
                <label for="upload_files"
                       class="uploads-file_label">
                    <input
                        placeholder="Загрузить документ"
                        id="upload_files"
                        type="file"
                        class="uploads-file_input">
                    <p class="text-contactFormGray text-base">{{ t('footer.form.uploadFile') }}</p>

                    <div class="uploads-file_text">
                      {{ t('footer.form.upload') }}
                    </div>
                </label>
            </div>

            <div class="relative">
                <textarea
                    v-model="contactsForm.body"
                    :placeholder="t('footer.form.message')"
                    type="text"
                    class="contacts-form_message"
                    :class="{'error-input': errors.body && errors.body.length}"
                    @input="errors.body = []"
                />
                <div class="absolute top-1/2 right-5 transform -translate-y-1/2">
                    <tippy
                        v-if="errors.body && errors.body.length"
                        theme="light"
                        placement="top-end"
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 7V10.75M19 10C19 11.1819 18.7672 12.3522 18.3149 13.4442C17.8626 14.5361 17.1997 15.5282 16.364 16.364C15.5282 17.1997 14.5361 17.8626 13.4442 18.3149C12.3522 18.7672 11.1819 19 10 19C8.8181 19 7.64778 18.7672 6.55585 18.3149C5.46392 17.8626 4.47177 17.1997 3.63604 16.364C2.80031 15.5282 2.13738 14.5361 1.68508 13.4442C1.23279 12.3522 1 11.1819 1 10C1 7.61305 1.94821 5.32387 3.63604 3.63604C5.32387 1.94821 7.61305 1 10 1C12.3869 1 14.6761 1.94821 16.364 3.63604C18.0518 5.32387 19 7.61305 19 10ZM10 13.75H10.008V13.758H10V13.75Z" stroke="#FF6939" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <template #content>
                            <div class="custom-tooltip">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.25 4.5V4C7.25 3.58579 7.58579 3.25 8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V4.5C8.75 4.91421 8.41421 5.25 8 5.25C7.58579 5.25 7.25 4.91421 7.25 4.5ZM8.75 7.5V12C8.75 12.4142 8.41421 12.75 8 12.75C7.58579 12.75 7.25 12.4142 7.25 12V7.5C7.25 7.08579 7.58579 6.75 8 6.75C8.41421 6.75 8.75 7.08579 8.75 7.5Z"
                                        fill="#FF6939"/>
                                </svg>
                                <span>{{errors.body[0]}}</span>
                            </div>
                        </template>
                    </tippy>
                </div>
            </div>

            <div class="mt-[24px] flex flex-row justify-start items-start">
                <label class="checkbox_wrapper">
                    <input v-model="contactsForm.privacy" type="checkbox">
                    <span class="checkmark"></span>
                </label>
                <p class="text-white text-[14px] font-semibold poppins-font">
                  {{ t('footer.form.terms.first') }}
                    <a href="#" class="text-bg-orange text-[14px]">
                      {{ t('footer.form.terms.second') }}
                    </a>
                </p>
            </div>
            <div class="mt-[24px] flex flex-row justify-start items-start">
                <label class="checkbox_wrapper">
                    <input v-model="contactsForm.services" type="checkbox">
                    <span class="checkmark"></span>
                </label>
                <p class="text-white text-[14px] font-semibold poppins-font">
                  {{ t('footer.form.terms.terth') }}
                </p>
            </div>
        </div>
        <custom-button
            type="submit"
            width="245px"
            :text="t('send')"
            classes="contacts-form_submit magnetic"
            :isButton="true"
        />
    </form>
</template>

<script setup>

import {onMounted, reactive, ref} from "vue";
import AxiosIns from "@/services/apiService";
import CustomButton from "@/components/CustomButton.vue";
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import {Tippy} from "vue-tippy";
import CustomSelect from "@/components/Ui/CustomSelect.vue";
import {useI18n} from "vue-i18n";

const { t } = useI18n()
const selectedOfficeLabel = t('footer.form.chooseOffice')
const selectTypeQuestionOptionsLabel = t('footer.form.selectApplication')

let contacts = ref([])
let services = ref([])

const changeContact = (val) => {
    contactsForm.contact_id = val.id;
    errors.value.contact_id = []
}
const changeService = (val) => {
    contactsForm.service_id = val.id;
    errors.value.service_id = []
}

const contactsForm = reactive({
    name: '',
    email: '',
    phone: '',
    contact_id: '0',
    service_id: '0',
    body: '',
    privacy: true,
    services: true,
})

let errors = ref({
    name: [],
    email: [],
    phone: [],
    contact_id: [],
    service_id: [],
    body: [],
    privacy: true,
    services: true,
})

const submitHandler = async () => {
    const {data} = await AxiosIns.postData("/api/user-request", contactsForm);

    if(data.errors) {
        errors.value = data.errors
    } else {
        for(let key in contactsForm) {
            if(key === 'contact_id' || key === 'service_id') {
                contactsForm[key] = '0'
            } else {
                contactsForm[key] = ''
            }
        }
    }
}

onMounted( async () => {
    const {data} = await AxiosIns.getData("/api/user-request-data");
    contacts.value = data.contacts
    services.value = data.services
})

</script>

<style scoped>
.checkbox_wrapper {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.error-input {
    border: 1px solid #FF5C01;
}

.error-input::placeholder {
    color: #FF5C01;
}

/* Hide the browser's default checkbox */
.checkbox_wrapper input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 1px solid #E8E7EA;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.checkbox_wrapper:hover input ~ .checkmark {
    background-color: #FF6939;
    border: none;
}

/* When the checkbox is checked, add a blue background */
.checkbox_wrapper input:checked ~ .checkmark {
    background-color: #FF6939;
    border: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_wrapper input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_wrapper .checkmark:after {
    left: 9px;
    top: 5px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
<style>
.error-input .vti__input::placeholder {
    color: #FF5C01;
}
</style>