<template>
  <footer v-if="!isAboutPage" class="footer-wrap">
    <div class="container pt-[300px] z-10">
      <div class="flex flex-col-reverse md:flex-row justify-center items-center">
        <img src="../../../public/images/footer_image.png" class="pt-[50px] pr-0 md:pt-0 md:pr-[100px]" alt="">
        <div class="flex-col-start">
          <h3 class="text-[48px] font-semibold text-white mb-[40px]">
            {{ t('footer.anyQuestions') }}<br>{{ t('footer.weWillContactYou') }}
          </h3>
            <custom-button
                    width="245px"
                    height="60px"
                    :text="t('writeToUs')"
                    classes="footer-btn magnetic"
                    :isButton="true"
                    @click="openModal"
            />
        </div>
      </div>
      <div class="flex flex-row justify-between items-center flex-wrap mt-[145px]">
        <div class="footer-block mb-[30px]">
          <div class="flex flex-col items-start mr-[88px] mb-[20px]">
            <p class="text-2xl text-white mb-[12px]">oasisexperience.cc</p>
            <p class="footer-text poppins-font">Indonesia, Bali</p>
          </div>
            <footer-contacts />
        </div>
        <footer-social-icons />
      </div>
    </div>
    <div class="w-full bg-black py-[30px] mt-[30px] lg:mt-0 flex-center">
      <div class="container footer-block mt-0">
        <p class="text-base text-white poppins-font">All rights reserved oasisexperience.cc</p>
        <p class="text-base text-white poppins-font font-[600]">
          <a href="#"
             class="mr-[16px] decoration-solid underline decoration-[2px]">
            Privacy policy</a> /
          <a href="#" class="ml-[16px] decoration-solid underline decoration-[2px]">
            Cookie notice</a>
        </p>
      </div>
    </div>
  </footer>
  <FooterPopup
      :showModal="showModal"
      @close="closeModal"
  />
</template>

<script setup>
import {computed, ref} from 'vue'
import {useRoute} from 'vue-router'
import FooterPopup from "@/components/FooterPopup.vue";
import FooterSocialIcons from "@/components/FooterSocialIcons.vue";
import FooterContacts from "@/components/FooterContacts.vue";
import CustomButton from "@/components/CustomButton.vue";
import {useI18n} from "vue-i18n";

const route = useRoute()
const isAboutPage = computed(() => route.path === '/contacts')

let showModal = ref(false)
const openModal = () => {
  showModal.value = true;
  document.querySelector('body').classList.remove('overflow-auto');
  document.querySelector('body').classList.add('overflow-hidden');
}
const closeModal = () => {
  showModal.value = false;
  document.querySelector('body').classList.remove('overflow-hidden');
  document.querySelector('body').classList.add('overflow-auto');
}


const { t } = useI18n()

</script>

