export const ru = {
    menu: 'Меню',
    news: 'Новости',
    aboutUs: 'О Нас',
    contacts: 'Контакты',
    whyChooseUs: 'Почему Выбирают Нас?',
    faq: 'Вопросы и ответы',
    bussinesConsaltings: 'Бизнес и Консалтинг',
    services: 'Наши услуги и сервисы',
    journal: 'Пресс-центр',
    tours: 'Туры',
    reviews: 'Отзывы о нас',
    ourPopularServicesAndOfferings: 'Наши популярные сервисы и услуги',
    allServices: 'Все услуги',
    more: 'Подробнее',
    writeToUs: 'Написать нам',
    send: 'Отправить',
    increase: 'Увеличить',
    allReviews: 'Все отзывы',
    overview: 'Обзор',
    dragDrop: 'Перетащите сюда файл для загрузки или выберите на компьютере',
    authorization: 'Авторизация',
    registration: 'Регистрация',
    myProfile: 'Мой профиль',
    travelers: 'Путешественники',
    myVisas: 'Мои визы',
    myTours: 'Мои туры',
    logout: 'Выход',
    otherServices: 'Другие услуги',
    backToHome: 'Вернуться на главную',
    answer: 'Ответ',
    writeFeedback: 'Написать отзыв',
    seeMore: 'Показать еще',
    days: 'дней',
    writeReview: 'Оставьте ваш отзыв о работе с нами',

    header: {
        home: 'Главная',
        businessInBali: 'Бизнес и Консалтинг',
        aboutUs: 'О нас',
        transportRental: 'Аренда транспорта',
        questionAnswers: 'Вопросы ответы',
        services: 'Сервисы и услуги',
        contacts: 'Контакты',
        pressCenter: 'Прессцентр',
        tours: 'Туры',
        buildRoute: 'Построить маршрут',
        addressCanggu: '129090 г. Москва, метро Проспект Мира, улица Щепкина, д. 29',
        addressUluwatu: '129090 г. Москва, метро Проспект Мира, улица Щепкина, д. 29',
        addressJimbaran: '129090 г. Москва, метро Проспект Мира, улица Щепкина, д. 29',
    },
    footer: {
        weWillContactYou: 'Мы свяжемся!',
        anyQuestions: 'Остались вопросы?',
        descAnyQuestions: 'Если у вас остались вопросы воспользуйтесь формой для связи ниже. Мы всегда готовы вам помочь и дать ответы на ваши вопросы.',
        navigator: 'Построить маршрут',
        form: {
            title: 'Форма для связи.',
            name: 'Имя*',
            email: 'Email',
            phone: 'Ваш номер телефона*',
            chooseOffice: 'Выберите офис',
            selectApplication: 'Выберите тип заявки',
            upload: 'Загрузить',
            uploadFile: 'Загрузить файл',
            message: 'Ваше сообщение',
            terms: {
                first: '*Я прочитал и понял, что мои персональные данные будут обрабатываться в рамках текущего процесса найма, как описано в',
                second: 'Уведомлении о конфиденциальности для соискателей.',
                terth: 'Я согласен получать информацию от OasisExperience.cc, связанную с предлагаемыми ими услугами.'
            },
            successfullySent: 'Ваша заявка успешно отправлена!',
        }
    },
    home: {
        heroSubTitle: 'Мы поможем с открытием',
        heroVisa: 'Туры',
        heroKitas: 'Консалтинг',
        heroBussines: 'Визы',
        heroInIndonesia: ' в Индонезии',
        heroDescriptions: 'Откройте для себя Бали с помощью местных экспертов. Консультируйтесь и планируйте свое путешествие по Бали с нашей помощью - от визы до пункта назначения, мы заботимся о вас на протяжении всего вашего пребывания.'

    },
    about: {
        team: 'Наша команда',
        ourServices: 'Наши услуги'
    },
    business: {
        companyType: 'Типы компаний',
        investmentType: 'Направления инвестиций',
        otherDirections: 'Другое направление',
        otherDirectionsDeck: 'Не нашли свое направление выше оставьте заявку на индивидуальную консультацию.',
        services: 'Услуги для бизнесса',
        bussinesArticles: 'Статьи про бизнес',
        consultation: 'Записаться на консультацию'
    },
    homeResult:{
        eliteServices: 'Элитные услуги',
        customerSatisfaction: 'Удовлетворенность клиентов',
        clients: 'Клиентов',
        aroundTheClock: 'Круглосуточно',
    },
    service: {
        generalInformation: 'Общая информация',
        important: 'Важно знать',
        documents: 'Документы',
        stages: 'Этапы',
        stagesObtaining: 'Этапы получения',
        rates: 'Тарифы',
        whatPurpose: 'Для каких целей виза?',
        submitApplication: 'Оставить заявку'
    },
    auth: {
        signIn: 'Войти',
        signUp: 'Зарегистрироваться',
        signInApple: 'Войти через Apple',
        signInGoogle: 'Войти через Google',
        signUpApple: 'Apple',
        signUpGoogle: 'Google',
        socialCampaigns: 'Ваши социальные кампании',
        orEmail: 'Или по электронной почте',
        forgotPassword: 'Забыли пароль',
        password: 'Пароль',
        notMember: 'Еще не зарегистрированы?',
        submit: 'Отправить',
        alreadyHave: 'У вас уже есть аккаунт?',
        enterEmail: 'Введите Ваш электронный адрес, чтобы сбросить пароль.',
        registerUsing: 'Зарегистрируйтесь, используя учетную запись Google или AppleId.',
        name: 'Имя',
        surename: 'Фамилия',
        repeatPassword: 'Повторите пароль',
        passwordRequirement: 'Используйте 8 или более символов, сочетая буквы, цифры и одну заглавную букву.',
        accept: 'Я принимаю',
        terms: 'Условия',
        setupNewPassword: 'Установить новый пароль',
        alreadyReset: 'Вы уже сбросили пароль?'
    },
}