<template>
    <div
        class="header-wrapper fixed-header"
        :class="{
            'header-shadow': (!isWhiteText && scrolledTop) || (!isWhiteText && isVisaB211),
            'z-0' : isNewsModal
        }"
    >
        <!-- Header body start-->
        <div
            :class="{
            'is-hidden': isHeaderHidden,
            'rounded-b-[24px]' : !isWhiteText && scrolledTop
        }"
            class="header relative"
        >
            <div
                :class="{
                'is-hidden': isHeaderHidden,
                'rounded-b-[24px]' : !isWhiteText && scrolledTop
            }"
                class="header_subst header-content"
            ></div>
            <div
                class="header-flex-between-center flex-wrap py-[24px]"
                :class="{
                'z-[9999] relative bg-white container': activeMenu,
                'container': !activeMenu,
            }"
            >
                <div
                    class="header_left header-flex-between-center"
                    :class="{'h-[24px] site-logo': !activeMenu}"
                >
                  <button
                      ref="buttonWrapper"
                      id="button-wrapper"
                      type="button"
                      class="magnetic-wrapper"
                      @click="activeMenuToggle"
                  >
                    <div id="button-circle"></div>
                    <div id="menu-burger">
                        <span class="menu-line" :class="isWhiteText && !activeMenu ? 'bg-white' : 'bg-black'"></span>
                        <span class="menu-line" :class="isWhiteText && !activeMenu ? 'bg-white' : 'bg-black'"></span>
                    </div>
                  </button>
                  <ul class="lg:block hidden">
                    <li style="font-size: 1.25rem" :class="isWhiteText && !activeMenu ? 'text-[white]' : 'text-[black]'" class="mr-4 z-0">
                        {{ t('menu') }}
                    </li>
                  </ul>
                    <!--Header burger end -->
                    <div class="header_line bg-line-color  opacity-50 w-[2px]  h-full lg:block hidden"></div>
                    <!--Header select -->
                    <language-switcher
                            v-if="!activeMenu"
                            :class="{ 'text-[white] bg-reviewsBorderColor-light': isWhiteText }"
                            class="lang-switcher focus:ring-blue-500 focus:border-blue-500"
                            @update-key="emits('updateKey')"
                    />
                </div>
                <router-link v-if="!activeMenu" to="/" class="header-title">
                    <img v-if="!isWhiteText" src="../../../public/images/header-title.svg" alt="">
                    <img v-if="isWhiteText" src="../../../public/images/header-title-white.svg" alt="">
                </router-link>
                <div v-if="!activeMenu" class="header-right-section">
                    <a href="https://wa.me/6281246729218?text=%D0%9F%D1%80%D0%B8%D0%B2%D0%B5%D1%82!%20%F0%9F%91%8B%20%D0%9C%D0%B5%D0%BD%D1%8F%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D1%83%D0%B5%D1%82%20%D0%B2%D0%B8%D0%B7%D0%B0%20%D0%B2%20%D0%98%D0%BD%D0%B4%D0%BE%D0%BD%D0%B5%D0%B7%D0%B8%D1%8E%20%D0%B8%D0%BB%D0%B8%20%D0%B4%D1%80%D1%83%D0%B3%D0%B0%D1%8F%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%D0%B0%20%D1%81%20%D0%B2%D0%B0%D1%88%D0%B5%D0%B3%D0%BE%20%D1%81%D0%B0%D0%B9%D1%82%D0%B0."
                       class=" flex flex-row header_contact header-phone lg:block hidden"
                    >
                        <span style="font-size: 1.25rem" :class="{ 'text-[#ffff]': isWhiteText }">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33079 15.6692C6.10457 13.4486 4.562 10.636 3.88609 7.56511C3.7751 6.97013 3.88774 6.35513 4.20239 5.83811C4.51704 5.3211 5.01154 4.93849 5.59099 4.76371L7.19409 4.25111L8.67549 7.08731L7.46389 8.29871L6.43389 9.32871L7.09799 10.6251C8.48364 13.3215 10.6787 15.5166 13.3751 16.9022L14.6715 17.5663L15.7015 16.5363L16.9129 15.3247L19.7491 16.8061L19.2365 18.4092C19.0617 18.9887 18.6791 19.4832 18.1621 19.7978C17.6451 20.1125 17.0301 20.2251 16.4351 20.1141C13.3642 19.4382 10.5516 17.8956 8.33099 15.6694L8.33079 15.6692ZM6.91659 17.0834C9.40625 19.581 12.5609 21.3109 16.0054 22.0672C17.0871 22.2861 18.2116 22.0953 19.1606 21.5319C20.1097 20.9685 20.8155 20.0726 21.1413 19.0181L22.1664 15.8122L16.5364 12.8722L14.2864 15.1222C11.9634 13.9276 10.0722 12.0362 8.87799 9.71301L11.128 7.46301L8.18799 1.83301L4.98189 2.85871C3.9274 3.18447 3.03152 3.89034 2.4681 4.83936C1.90469 5.78838 1.71391 6.91287 1.93279 7.99461C2.68915 11.4391 4.41901 14.5938 6.91659 17.0834Z"
                                :fill="isWhiteText ? 'white' : 'black'"
                          />
                          </svg>
                          <!--                          +62 812 832 22-33-->
                        </span>

                    </a>
                    <div class="header_line bg-line-color opacity-50 w-[2px] h-full lg:block hidden"></div>

                  <div
                      v-if="user.id"
                      class="relative"
                      @mouseenter="showProfileDropdown = true"
                      @click="showProfileDropdown = !showProfileDropdown"
                  >
                    <div
                         class="w-56 rounded-[16px] py-3 px-4 bg-black flex items-center justify-between"
                    >
                      <div class="flex">
                        <svg id="profile-icon" class="z-[9999]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 10C12.7911 10 13.5645 9.76541 14.2223 9.32588C14.8801 8.88635 15.3928 8.26164 15.6955 7.53074C15.9983 6.79983 16.0775 5.99556 15.9231 5.21964C15.7688 4.44372 15.3878 3.73098 14.8284 3.17157C14.269 2.61216 13.5563 2.2312 12.7804 2.07686C12.0044 1.92252 11.2002 2.00173 10.4693 2.30448C9.73836 2.60723 9.11365 3.11992 8.67412 3.77772C8.2346 4.43552 8 5.20888 8 6C8.00119 7.0605 8.423 8.07723 9.17289 8.82711C9.92278 9.577 10.9395 9.99881 12 10ZM12 4C12.3956 4 12.7822 4.1173 13.1111 4.33706C13.44 4.55683 13.6964 4.86918 13.8478 5.23463C13.9991 5.60009 14.0387 6.00222 13.9616 6.39018C13.8844 6.77814 13.6939 7.13451 13.4142 7.41422C13.1345 7.69392 12.7781 7.8844 12.3902 7.96157C12.0022 8.03874 11.6001 7.99914 11.2346 7.84776C10.8692 7.69639 10.5568 7.44004 10.3371 7.11114C10.1173 6.78224 10 6.39556 10 6C10.0006 5.46976 10.2115 4.9614 10.5865 4.58646C10.9614 4.21152 11.4698 4.00061 12 4ZM19.707 15.2041C17.6615 13.163 14.8897 12.0166 12 12.0166C9.11027 12.0166 6.33855 13.163 4.293 15.2041L4 15.4971V21H20V15.4971L19.707 15.2041ZM18 19H6V16.3383C7.64125 14.8445 9.78072 14.0166 12 14.0166C14.2193 14.0166 16.3588 14.8445 18 16.3383V19Z"
                                fill="white"
                          />
                        </svg>
                        <p class="text-white text-base font-medium ml-[10px]">{{user.first_name}} {{user.last_name}}</p>
                      </div>

                      <svg class="ease-in-out duration-100" :class="{'rotate-180': !showProfileDropdown}" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2433 7.18188L6.00028 2.93887L1.75727 7.18188L0.697266 6.12088L6.00028 0.817871L11.3033 6.12088L10.2433 7.18188Z" fill="#FDFDFD"/>
                      </svg>

                    </div>

                    <div
                        id="dropdown"
                        class="z-10 bg-white rounded-xl shadow w-40 absolute top-14 right-0"
                        :class="{
                              'hidden': !showProfileDropdown,
                              'w-56': auth
                            }"
                        @mouseleave="hideDropdown"
                    >
                      <DropdownMenu :auth="auth" />
                    </div>
                  </div>
                    <div v-else class="relative">
                        <a
                            href="#"
                            ref="profileButtonWrapper"
                            type="button"
                            class="header_profile profile-button-wrapper relative z-[9999]"
                            @mouseenter="profileMouseEnter"
                            @mouseleave="profileMouseLeave"
                            @mousemove="profileMouseMove"
                            @click="showDropdown"
                        >
                            <div id="profile-button-circle"></div>
                            <svg id="profile-icon" class="z-[9999]" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 10C12.7911 10 13.5645 9.76541 14.2223 9.32588C14.8801 8.88635 15.3928 8.26164 15.6955 7.53074C15.9983 6.79983 16.0775 5.99556 15.9231 5.21964C15.7688 4.44372 15.3878 3.73098 14.8284 3.17157C14.269 2.61216 13.5563 2.2312 12.7804 2.07686C12.0044 1.92252 11.2002 2.00173 10.4693 2.30448C9.73836 2.60723 9.11365 3.11992 8.67412 3.77772C8.2346 4.43552 8 5.20888 8 6C8.00119 7.0605 8.423 8.07723 9.17289 8.82711C9.92278 9.577 10.9395 9.99881 12 10ZM12 4C12.3956 4 12.7822 4.1173 13.1111 4.33706C13.44 4.55683 13.6964 4.86918 13.8478 5.23463C13.9991 5.60009 14.0387 6.00222 13.9616 6.39018C13.8844 6.77814 13.6939 7.13451 13.4142 7.41422C13.1345 7.69392 12.7781 7.8844 12.3902 7.96157C12.0022 8.03874 11.6001 7.99914 11.2346 7.84776C10.8692 7.69639 10.5568 7.44004 10.3371 7.11114C10.1173 6.78224 10 6.39556 10 6C10.0006 5.46976 10.2115 4.9614 10.5865 4.58646C10.9614 4.21152 11.4698 4.00061 12 4ZM19.707 15.2041C17.6615 13.163 14.8897 12.0166 12 12.0166C9.11027 12.0166 6.33855 13.163 4.293 15.2041L4 15.4971V21H20V15.4971L19.707 15.2041ZM18 19H6V16.3383C7.64125 14.8445 9.78072 14.0166 12 14.0166C14.2193 14.0166 16.3588 14.8445 18 16.3383V19Z"
                                      :fill="isWhiteText ? 'white' : 'black'"
                                />
                            </svg>
                        </a>

                        <div
                            id="dropdown"
                            class="z-10 bg-white rounded-xl shadow w-40 absolute top-10 right-0"
                            :class="{
                              'hidden': !showProfileDropdown,
                              'w-56': auth
                            }"
                            @mouseleave="hideDropdown"
                        >
                            <DropdownMenu :auth="auth" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Header body end-->
        <!-- Header Sub start-->
        <div
                v-if="isVisaB211"
                :class="{ 'is-hidden': isHeaderHidden }"
                class="header_sub header-sub_hidden"
        >
            <div class="header_sub-row header-flex-between-center container">
                <!-- col start-->
                <div class="header_sub-col flex items-center gap-6">
                    <div class="header_sub-icon rounded-full flex-center bg-gray-strong w-10 h-10">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.4205 17.2955C9.85983 16.8562 9.85983 16.1438 9.4205 15.7045L3.84099 10.125L16.875 10.125C17.4963 10.125 18 9.62132 18 9C18 8.37868 17.4963 7.875 16.875 7.875L3.84099 7.875L9.4205 2.29549C9.85984 1.85615 9.85984 1.14384 9.4205 0.704505C8.98116 0.265165 8.26885 0.265164 7.82951 0.704505L0.329507 8.2045C-0.109834 8.64384 -0.109834 9.35615 0.329507 9.79549L7.8295 17.2955C8.26884 17.7348 8.98116 17.7348 9.4205 17.2955Z"
                                  fill="#3772FF"/>
                        </svg>
                    </div>
                    <div class="header_sub-box flex items-center gap-[14px]">
                        <div class="sub-box_name text-[18px] font-semibold">Виза B211A (Offshore)</div>
                        <div class="sub-box_eclipse w-2 h-2 rounded-full bg-[#D9D9D9]"></div>
                        <div class="flex items-center gap-2">
                            <div class="sub-box_stats text-[16px] text-[#68737A] font-[450]">Регуляр 7-10 дней</div>
                            <div class="sub-box_price">280$</div>
                        </div>
                    </div>
                </div>
                <!-- col end-->
                <!-- col start-->
                <button class="header_sub-btn header-submit_btn">
                    <div>Оставить заявку</div>
                    <div>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5795 0.704505C8.14017 1.14384 8.14017 1.85616 8.5795 2.2955L14.159 7.875H1.125C0.50368 7.875 0 8.37868 0 9C0 9.62132 0.50368 10.125 1.125 10.125H14.159L8.5795 15.7045C8.14017 16.1438 8.14017 16.8562 8.5795 17.2955C9.01884 17.7348 9.73116 17.7348 10.1705 17.2955L17.6705 9.7955C18.1098 9.35616 18.1098 8.64384 17.6705 8.2045L10.1705 0.704505C9.73116 0.265165 9.01884 0.265165 8.5795 0.704505Z"
                                  fill="white"/>
                        </svg>
                    </div>
                </button>
                <!-- col end-->
            </div>
        </div>
        <!-- Header body start-->

            <div
                class="header-menu_menu"
                 :class="{
                'hidden-menu': !activeMenu,
                'visible-menu': activeMenu
            }"
            >

                <img src="../../../public/images/HomeHeaderPatternMobile1.svg"
                     class="base-header_img"
                     alt="">
                <div class="base-header_content container grid grid-rows-1 pl-[5px]"  id="nav-container">
                    <!-- ItemHeaderMenuLinks - start -->
                    <ul class="flex justify-start flex-col lg:flex-row lg:items-center flex-wrap lg:my-auto mt-8 mb-12 h-fit max-w-1/2">
                        <li
                            class="lg:w-[50%] mb-[24px] lg:mb-[40px] nav-item"
                            v-for="link in headerMenuLinkData"
                            :key="link.id"
                        >
                            <router-link
                                :to="link.path"
                                class="base-header_link group hover:text-blue focus:text-blue"
                                @click.passive="activeMenuToggle"
                            >
                                <span class="mr-[13px]">{{ t(link.name) }}</span>
                                <svg class="opacity-0 group-hover:opacity-100 group-focus:opacity-100 transition-all duration-900 mt-1" width="38" height="22" viewBox="0 0 38 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M25.1866 19.5432L26.9549 21.3115L37.2666 10.9998L26.9549 0.688172L25.1866 2.45651L32.4816 9.74984L0.666669 9.74984L0.666669 12.2498L32.4816 12.2498L25.1866 19.5432Z" fill="#00A725"/>
                                </svg>
                            </router-link>

                        </li>
                    </ul>
                    <!-- ItemHeaderMenuLinks - end -->
                    <div class="nav-footer base-header_menu max-w-screen-xl lg:overflow-hidden">
                        <!-- ItemHeaderMenu - start -->
                        <div
                            v-for="item in headerMenuItemData" :key="item.id"
                            class="base-header_menu_item max-w-1/3 mr-5 nav-link footer-item">
                            <div class="header-flex-between-start flex-col w-full z-10">
                                <h4 class="base-header_title">{{ item.title }}</h4>
                                <p class="base-header_address">{{ t(item.adress) }}</p>
                                <router-link
                                    :to="item.path"
                                    class="base-header_link_route"
                                >
                                    <svg class="mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 0L0 12L12 24L24 12L12 0ZM9.75 19.6287V14C9.75029 13.6686 9.88208 13.3508 10.1164 13.1164C10.3508 12.8821 10.6686 12.7503 11 12.75H13.7842V15.2159L17 12L13.7842 8.7841V11.25H11C10.2709 11.2507 9.57184 11.5407 9.05628 12.0563C8.54071 12.5718 8.25074 13.2709 8.25 14V18.1287L2.1213 12L12 2.1213L21.8787 12L12 21.8787L9.75 19.6287Z" fill="#0062DE"/>
                                    </svg>
                                    <span>{{t('header.buildRoute')}}</span>
                                </router-link>
                            </div>
                            <div class="mt-4 py-4 header-flex-between-start flex-col w-full border-t-[1px] border-gray-menu">
                                <a href="tel:+628125188878"
                                   class="header-block group">
                                    {{ item.number }}
                                    <!--                            <div class="header-underline duration-900"></div>-->
                                </a>
                                <a href="mailto:infooasisexperience.org"
                                   class="header-block group mt-2"
                                >
                                    {{ item.email }}
                                    <!--                            <div class="header-underline duration-900"></div>-->
                                </a>
                            </div>
                        </div>
                        <!-- ItemHeaderMenu - end -->
                    </div>
                </div>
                <slot></slot>
            </div>

    </div>
</template>

<script setup>
import {computed, ref, watch, onMounted, onUnmounted} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { gsap, Power2 } from 'gsap';

import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n'
import DropdownMenu from "@/components/Dropdown/DropdownMenu.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

const { t } = useI18n()

let showProfileDropdown = ref(false)
let auth = localStorage.getItem('token')

const emits = defineEmits(['updateKey'])

const store = useStore();
const route = useRoute();
const router = useRouter();
const activeMenu = ref(false);
const scrolledTop = ref(false);
const buttonWrapper = ref(null);
const profileButtonWrapper = ref(null);

let width = ref(window.innerWidth)

onUnmounted(() => {
    window.removeEventListener("resize", resizeHandler);
})

const resizeHandler = () => {
    width.value = window.innerWidth
}

const headerMenuLinkData = ref([
  {id: 1, name: 'header.home', path: '/'},
  {id: 2, name: 'header.businessInBali', path: '/business-consulting'},
  {id: 3, name: 'header.aboutUs', path: '/about'},
  {id: 4, name: 'header.transportRental', path: '/vehicle-rental'},
  {id: 5, name: 'header.questionAnswers', path: '/questions'},
  {id: 6, name: 'header.services', path: '/services'},
  {id: 7, name: 'header.contacts', path: '/contacts'},
  {id: 8, name: 'header.pressCenter', path: '/journals'},
  {id: 9, name: 'header.tours', path: '/tours'},
])

const headerMenuItemData = ref([
  {
    id: 1,
    title: 'Canggu',
    adress: 'header.addressCanggu',
    path: '/services/Visa-B211A',
    number: ' +62 (812) 518-88-78',
    email: ' infooasisexperience.org'
  },
  {
    id: 2,
    title: 'Uluwatu',
    adress: 'header.addressUluwatu',
    path: '/services/Visa-B211A',
    number: '  +62 (812) 518-88-78',
    email: ' infooasisexperience.org'
  },
  {
    id: 3,
    title: 'Jimbaran',
    adress: 'header.addressJimbaran',
    path: '/services/Visa-B211A',
    number: ' +62 (812) 518-88-78',
    email: ' infooasisexperience.org'
  },
])

const createTimeline = () => {
  const tl = gsap.timeline({
    paused: true,
    defaults: { duration: 0.1, ease: 'power1.inOut' },
  });

  tl.fromTo('.site-logo', { opacity: 0 }, { opacity: 1 }, '+=0.1');
  tl.fromTo('.nav-item a', { y: '2em' }, { y: '0em' }, '+=0.1');
  tl.fromTo('.nav-footer .footer-item', { opacity: 0, y: '2em' }, { opacity: 1, y: '0em' }, '+=0.3');

  return tl;
};

const activeMenuToggle = () => {
    const tl = createTimeline();
    const menuBurger = buttonWrapper.value.querySelector('#menu-burger');

    if (!activeMenu.value) {
        activeMenu.value = true;
        document.body.classList.add('overflow-hidden');
        tl.play();
        animateMenuToggle(activeMenu.value, menuBurger);

    } else {
        document.body.classList.remove('overflow-hidden');
        tl.reverse(0);
        tl.eventCallback('onReverseComplete', () => {
            activeMenu.value = false;
            checkWhiteText();
            animateMenuToggle(activeMenu.value, menuBurger);
        });
    }
};

const checkWhiteText = () => {
  if (route.path !== '/' && !activeMenu.value) {
    isWhiteText.value = true;
  } else {
    isWhiteText.value = false;
  }

  if (window.pageYOffset !== 0) {
    isWhiteText.value = false;
  }
};

const isVisaB211 = computed(() => route.path === '/services/Visa-B211A');
const isWhiteText = ref(false);

onMounted(async () => {
    await router.isReady();
    window.addEventListener("resize", resizeHandler);
    initMagneticButton();
    checkWhiteText();
});

gsap.registerPlugin(ScrollTrigger)
const isHeaderHidden = ref(false)
let lastScroll = 0;

watch(
    [isHeaderHidden, route],
    ([value]) => {
        scrollWatch(value)
    },
);

const scrollWatch = (value) => {
    gsap.to('.header-wrapper', {
        yPercent: value ? -100 : 0,
        duration: 0.5,
        ease: 'power2.out',

    })
    if (window.pageYOffset === 0) {
        checkWhiteText()
        gsap.to('.header_subst', {
            backgroundColor: 'transparent',
            backdropFilter: "blur(0px)",
            opacity: 0,
        });
    } else if (window.pageYOffset !== 0) {
        isWhiteText.value = false
        gsap.to('.header_subst', {
            backgroundColor: '#ffffff',
            opacity: 1,

        })
    }
}

ScrollTrigger.create({
  start: 'top',
  onEnter: () => (isHeaderHidden.value = false),
  onLeaveBack: () => (isHeaderHidden.value = true),
  onLeave: () => (isHeaderHidden.value = true),

})

// Следим за скроллом страницы при помощи события "scroll"

window.addEventListener('scroll', () => {
  const currentScroll = window.pageYOffset;

  if (route.path === '/services/Visa-B211A') {
    if (currentScroll > 400) {
      isHeaderHidden.value = currentScroll > lastScroll && currentScroll > 0 ? true : false;
      gsap.to('.header-wrapper', {
        yPercent: isHeaderHidden.value ? -55 : 0,
        duration: 0.5,
        ease: 'power2.out',

      });
      gsap.to('.header_sub', {
        display: 'hidden',
      });
    } else {
      isHeaderHidden.value = currentScroll > lastScroll && currentScroll > 0 ? true : false;
      gsap.to('.header-wrapper', {
        yPercent: isHeaderHidden.value ? -100 : 0,
        duration: 0.5,
        ease: 'power2.out',
        boxShadow: 'none',

      });
      gsap.to('.header_sub', {
        opacity: isHeaderHidden.value ? '1' : '0',
        duration: 0.5,
        visibility: 'visible',
        display: 'block',
        ease: 'power2.out',
      });
    }
  } else {
    isHeaderHidden.value = currentScroll > lastScroll && currentScroll > 0 ? true : false;
  }

  scrollWatch(isHeaderHidden.value)

  scrolledTop.value = currentScroll < lastScroll && currentScroll >  0

  store.commit('setShowScrolledMenu', isHeaderHidden.value)
  lastScroll = currentScroll;
});

const user = computed(() => store.state.user)

const isNewsModal = computed(() => store.state.isNewsModal)

const magneticEffect = (element, e, target, movement) => {
  const boundingRect = element.getBoundingClientRect();
  const relX = e.pageX - boundingRect.left;
  const relY = e.pageY - boundingRect.top;
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

  gsap.to(target, 0.3, {
    x: ((relX - boundingRect.width / 2) / boundingRect.width) * movement,
    y:
        ((relY - boundingRect.height / 2 - scrollTop) / boundingRect.width) *
        movement,
    ease: Power2.easeOut
  });
};

const initMagneticButton = () => {
  const buttonCircle = buttonWrapper.value.querySelector('#button-circle');
  const menuBurger = buttonWrapper.value.querySelector('#menu-burger');

  buttonWrapper.value.addEventListener('mouseenter', () => {
    gsap.to(buttonCircle, 0.3, { scale: 1.3, backgroundColor: "#000" });
    buttonWrapper.value.classList.add('hover');
  });

  buttonWrapper.value.addEventListener('mouseleave', () => {
    gsap.to([buttonCircle, menuBurger], 0.3, {
      scale: 1,
      x: 0,
      y: 0
    });
    gsap.to(buttonCircle, 0.3, { backgroundColor: "transparent" });
    buttonWrapper.value.classList.remove('hover');
  });

  buttonWrapper.value.addEventListener('mousemove', (e) => {
      if(width.value < 768) return;
    magneticEffect(buttonWrapper.value, e, buttonCircle, 30);
    magneticEffect(buttonWrapper.value, e, menuBurger, 30);
  });

};

const showDropdown = () => {
    if(auth.value) {
        showProfileDropdown.value = !showProfileDropdown.value
    }
}

const hideDropdown = () => {
    showProfileDropdown.value = false
}
const profileMouseEnter = () => {
    showProfileDropdown.value = true
    const buttonCircle = profileButtonWrapper.value.querySelector('#profile-button-circle');

    gsap.to(buttonCircle, 0.3, { scale: 1.3, backgroundColor: "#000" });
    profileButtonWrapper.value.classList.add('hover');
}

const profileMouseLeave = () => {
    const buttonCircle = profileButtonWrapper.value.querySelector('#profile-button-circle');
    const menuBurger = profileButtonWrapper.value.querySelector('#profile-icon');
    gsap.to([buttonCircle, menuBurger], 0.3, {
        scale: 1,
        x: 0,
        y: 0
    });

    gsap.to(buttonCircle, 0.3, { backgroundColor: "transparent" });
    profileButtonWrapper.value.classList.remove('hover');
}

const profileMouseMove = () => {
    const buttonCircle = profileButtonWrapper.value.querySelector('#profile-button-circle');
    const menuBurger = profileButtonWrapper.value.querySelector('#profile-icon');
    if(width.value < 768) return;
    magneticEffect(profileButtonWrapper.value, event, buttonCircle, 30);
    magneticEffect(profileButtonWrapper.value, event, menuBurger, 30);
}

const animateMenuToggle = (menuActive, menuBurger) => {
  const menuLines = menuBurger.querySelectorAll('.menu-line');
  const easing = 'Power2.easeOut';
  const duration = 0.05;

  if (menuActive) {
    gsap.to(menuLines[0], duration, { y: 4, ease: easing });
    gsap.to(menuLines[1], duration, { y: -4, ease: easing });

    gsap.to(menuLines[0], duration, { rotation: 45, ease: easing, delay: duration });
    gsap.to(menuLines[1], duration, { rotation: -45, ease: easing, delay: duration * 2 });
  } else {
    gsap.to(menuLines[0], duration, { rotation: 0, y: 0, ease: easing });
    gsap.to(menuLines[1], duration, { rotation: 0, y: 0, ease: easing });
  }
};


</script>


<style scoped>

.hidden-menu {
    display: none;
}

.visible-menu {
    display: block;
}
.nav-item {
    overflow: hidden;
    height: 48px;
}
.nav-item a{
    transition: 0.6s;
    transform: translateY(2em);
}
.nav-footer {
    height: 240px;
}
.nav-footer .footer-item {
    transition: 0.6s;
    opacity: 0;
    transform: translateY(2em);
}
#nav-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

#nav-container::-webkit-scrollbar {
    display: none;
}

* {
  box-sizing: border-box;
}

#button-wrapper {
  width: 60px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.profile-button-wrapper {
  width: 40px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

#button-circle {
  width: 40px;
  height: 40px;
  border-radius: 32px;
  box-sizing: border-box;
  /*border: 2px solid rgba(0, 0, 0);*/
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  margin-left: -20px;
  cursor: pointer;
  z-index:5;
}
#profile-button-circle {
  width: 40px;
  height: 40px;
  border-radius: 32px;
  box-sizing: border-box;
  /*border: 2px solid rgba(0, 0, 0);*/
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  margin-left: -20px;
  cursor: pointer;
  z-index:5;
}

#menu-burger {
  width: 24px;
  height: 22px;
  position: relative;
  margin: 0 auto;
  pointer-events: initial;
  z-index: 999;
}

#menu-burger .menu-line {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  opacity: 1;
  right: 0;
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
  transition-property: background, transform, top;
  z-index: 999;
}

#menu-burger .menu-line:nth-child(1) {
  top: 6px;
}

#menu-burger .menu-line:nth-child(2) {
  top: 14px;
}


#button-wrapper:hover #menu-burger .menu-line {
  /*background: #fff;*/
}

#button-wrapper.burger-active #menu-burger .menu-line:nth-child(1) {
  top: 6px;
  /*transform: rotate(45deg);*/
}

#button-wrapper.burger-active #menu-burger .menu-line:nth-child(2) {
  top: 14px;
}
.hover #profile-icon path{
    fill: white!important;
}
@media (min-width: 768px) {
    .hover .menu-line {
        background: white!important;
    }
}
@media(max-width: 768px) {
    #button-wrapper {
        width: fit-content;
    }
    #menu-burger {
        margin: 0;
    }
    #button-circle {
        background: transparent!important;
    }
    #profile-button-circle {
        background: transparent!important;
    }
    /*.menu-line {*/
    /*    background: black!important;*/
    /*}*/
    .header-title img {
        width: 150px;
    }
}


</style>